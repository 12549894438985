import React, { Component, Fragment } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import logo from "../imgs/Group 2.png";
import { Link } from "react-router-dom";
import Arrow1 from "../imgs/Icon awesome-arrow-left.png";
import Arrow from "../imgs/Icon awesome-arrow-right.png";
import Checkbox from "@mui/material/Checkbox";
import { Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";

export class Summary extends Component {
  ht = {
    color: "#FB2306",
  };
  first = () => {
    this.props.firstStep();
  };
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };
  constructor(props) {
    super(props);

    this.subRef = React.createRef();
    this.state = {
      disabled: false,
      openT: false,
    };
  }
  handleOpenT = () => {
    this.setState({
      openT: true,
    });
  };
  handleCloseT = () => {
    this.setState({
      openT: false,
    });
  };
  upload = async (e) => {
    e.preventDefault();
    const {
      values: {
        name,
        email,
        phone,
        position,
        country,
        businessName,
        businessPhone,
        type,
        category,
        fileList,
        notes,
        Instagram,
        Facebook,
        website,
        goal,
        interest,
        ovens,
        mixers,
        processors,
        slicers,
        bladeSize,
        microwave,
        ice,
        Grills,
        Juicers,
      },
    } = this.props;
    try {
      const response = await fetch(
        "https://v1.nocodeapi.com/fadidev/google_sheets/yEdYhzSPMDLnsjLW?tabId=Ghost",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify([
            [
              name,
              email,
              phone,
              position,
              country,
              businessName,
              businessPhone,
              type,
              category.toString(),
              fileList.toString(),
              notes,
              Instagram,
              Facebook,
              website,
              goal,
              interest,
              ovens,
              mixers,
              processors,
              slicers,
              bladeSize,
              microwave,
              ice,
              Grills,
              Juicers,
            ],
          ]),
        }
      );
      await response.json();
    } catch (error) {
      console.log(error);
    }
  };

  // handleCheck = (e) => {
  //   e.preventDefault();
  //   this.props.handleCheck("TnC");
  // };
  stepValidation = () => {
    const {
      values: { TnC },
    } = this.props;
    let isValid = true;
    if (!TnC) {
      alert("Please Accept Terms & Conditions");
      isValid = false;
    }

    return isValid;
  };

  submit = (e) => {
    e.preventDefault();
    let isValid = this.stepValidation();
    if (isValid) {
      this.upload(e);
      this.first();
    }
  };
  render() {
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "70%",
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
      maxHeight: "50%",
      overflow: "auto",
      borderRadius: 3,
    };
    const {
      handleCheck,
      values: {
        name,
        email,
        phone,
        position,
        country,
        businessName,
        businessPhone,
        type,
        category,
        notes,
        ov,
        mx,
        pro,
        slicers,
        bladeSize,
        microwave,
        ice,
        grl,
        juc,
      },
    } = this.props;
    return (
      <div>
        {/* Header */}
        <div>
          <nav>
            <div className="sm:flex w-full grid grid-rows-1 grid-cols-6 sm:grid-cols-5 content-center items-center">
              <div className="sm:w-full  sm:px-2 w-50 py-4 pl-16">
                <Link to="/">
                  <img src={logo} alt="Host & Ghost" className="w-20 sm:w-14" />
                </Link>
              </div>
              <div className="sm:hidden"></div>
              <div className="sm:hidden flex items-center content-center">
                <span className="bg-[#FB2306] md:text-[15px] md:w-[2.5rem] md:h-[1.5rem] w-7 h-7 rounded-full grid items-center text-white text-[20px] font-metrischbold">
                  1
                </span>
                <span className="grid w-[124pt] md:text-[15px] text-[20px] font-metrischbold text-left pl-2">
                  About You
                </span>
              </div>
              <div className="sm:hidden flex items-center content-center w-60">
                <span className="bg-[#FB2306] md:text-[15px] md:w-6 md:h-6 sm:w-5 sm:h-5 w-7 h-7 rounded-full grid items-center text-white sm:text-[14px] text-[20px] font-metrischbold">
                  2
                </span>
                <span className="grid w-[124pt] md:text-[15px] md:w-[6rem] sm:w-max sm:text-[14px] text-[20px] font-metrischbold text-left sm:pl-1 pl-2 sm:mr-4">
                  About Your Business
                </span>
              </div>
              <div className=" sm:hidden flex items-center content-center">
                <span className="bg-[#FB2306] md:text-[15px] sm:text-[14px] md:w-[2.5rem] md:h-[1.5rem] w-7 h-7 rounded-full grid items-center text-white text-[20px] font-metrischbold">
                  3
                </span>
                <span className="grid w-[124pt] md:text-[15px] sm:text-[14px] text-[20px] font-metrischbold text-left pl-2">
                  Your Goals
                </span>
              </div>
              <div className=" flex items-center content-center">
                <span className="bg-[#FB2306] md:text-[15px] md:w-[2.5rem] md:h-[1.5rem] w-7 h-7 rounded-full grid items-center text-white text-[20px] font-metrischbold">
                  4
                </span>
                <span className="grid w-[124pt] md:text-[15px]  text-[20px] font-metrischbold text-left pl-2">
                  Your Needs
                </span>
              </div>
            </div>
          </nav>
        </div>
        <Fragment>
          <div className="">
            <ProgressBar
              bgColor="#FB2306"
              baseBgColor="#f7f7f7"
              initCompletedOnAnimation={87.5}
              // animateOnRender={true}
              completed={100}
              isLabelVisible={false}
              height="15px"
            />
          </div>

          <div className="p-[2rem]">
            <h2 className="flex justify-start md:text-[40px] sm:text-[30px] text-[50px] font-metrischextrabold -mb-4 sm:-mb-1">
              Summary
            </h2>
            <h2 className="flex justify-start md:text-[20px] sm:text-[20px] text-[25px] text-[#FB2306] font-metrischbold sm:text-left md:mt-4">
              A summary about your onboarding process
            </h2>
            <br />
            <h2 className="flex justify-start md:text-[20px] sm:text-[20px] text-[25px] text-[#FB2306] font-metrischbold">
              About You
            </h2>
            <div className="grid grid-cols-4 md:grid-cols-3 sm:grid-cols-2">
              <div className="flex justify-start md:text-[20px] sm:text-[20px] text-[25px] font-metrischbold text-left sm:text-left">
                Name
              </div>
              <div className="flex justify-start items-center md:text-[20px] sm:text-[20px] text-[25px] opacity-50 font-metrisch text-left">
                {name}
              </div>
              <div></div>
              <div></div>
            </div>
            <div className="grid grid-cols-4 md:grid-cols-3 sm:grid-cols-2">
              <div className="flex justify-start md:text-[20px] sm:text-[20px] text-[25px] font-metrischbold text-left sm:text-left">
                Email
              </div>
              <div className="flex justify-start items-center md:text-[20px] sm:text-[20px] text-[25px] opacity-50 font-metrisch text-left ">
                {email}
              </div>
              <div></div>
              <div></div>
            </div>
            <div className="grid grid-cols-4 md:grid-cols-3 sm:grid-cols-2">
              <div className="flex justify-start md:text-[20px] sm:text-[20px] text-[25px] font-metrischbold text-left sm:text-left">
                Phone Number
              </div>
              <div className="flex justify-start items-center md:text-[20px] sm:text-[20px] text-[25px] opacity-50 font-metrisch text-left">
                {phone}
              </div>
              <div></div>
              <div></div>
            </div>
            <div className="grid grid-cols-4 md:grid-cols-3 sm:grid-cols-2">
              <div className="flex justify-start md:text-[20px] sm:text-[20px] text-[25px] font-metrischbold text-left sm:text-left">
                Company Position
              </div>
              <div className="flex justify-start items-center md:text-[20px] sm:text-[20px] text-[25px] opacity-50 font-metrisch text-left">
                {position}
              </div>
              <div></div>
              <div></div>
            </div>
            <hr className="text-[#FB2306] border-[#FB2306] border-dashed my-4" />
            <h2 className="flex justify-start md:text-[20px] sm:text-[20px] text-[25px] text-[#FB2306] font-metrischbold">
              About Your Business
            </h2>
            <div className="grid grid-cols-4 md:grid-cols-3 sm:grid-cols-2">
              <div className="flex justify-start md:text-[20px] sm:text-[20px] text-[25px] font-metrischbold text-left sm:text-left">
                Your Country
              </div>
              <div className="flex justify-start items-center md:text-[20px] sm:text-[20px] text-[25px] opacity-50 font-metrisch text-left">
                {country}
              </div>
              <div></div>
              <div></div>
            </div>
            <div className="grid grid-cols-4 md:grid-cols-3 sm:grid-cols-2">
              <div className="flex justify-start md:text-[20px] sm:text-[20px] text-[25px] font-metrischbold text-left sm:text-left">
                Business Name
              </div>
              <div className="flex justify-start items-center md:text-[20px] sm:text-[20px] text-[25px] opacity-50 font-metrisch text-left">
                {businessName}
              </div>
              <div></div>
              <div></div>
            </div>
            <div className="grid grid-cols-4 md:grid-cols-3 sm:grid-cols-2">
              <div className="flex justify-start md:text-[20px] sm:text-[20px] text-[25px] font-metrischbold text-left sm:text-left">
                Business Phone Number
              </div>
              <div className="flex justify-start items-center md:text-[20px] sm:text-[20px] text-[25px] opacity-50 font-metrisch text-left">
                {businessPhone}
              </div>
              <div></div>
              <div></div>
            </div>
            <div className="grid grid-cols-4 md:grid-cols-3 sm:grid-cols-2">
              <div className="flex justify-start md:text-[20px] sm:text-[20px] text-[25px] font-metrischbold text-left sm:text-left">
                Business Type
              </div>
              <div className="flex justify-start items-center md:text-[20px] sm:text-[20px] text-[25px] opacity-50 font-metrisch text-left">
                {type}
              </div>
              <div></div>
              <div></div>
            </div>
            <div className="grid grid-cols-4 md:grid-cols-3 sm:grid-cols-2">
              <div className="flex justify-start md:text-[20px] sm:text-[20px] text-[25px] font-metrischbold text-left sm:text-left">
                Food Category
              </div>
              <div className="flex justify-start items-center md:text-[20px] sm:text-[20px] text-[25px] opacity-50 font-metrisch text-left">
                {category}
              </div>
              <div></div>
              <div></div>
            </div>
            <div className="grid grid-cols-4 md:grid-cols-3 sm:grid-cols-2">
              <div className="flex justify-start md:text-[20px] sm:text-[20px] text-[25px] font-metrischbold text-left sm:text-left">
                Your Notes
              </div>
              <div className="flex justify-start items-center md:text-[20px] sm:text-[20px] text-[25px] opacity-50 font-metrisch text-left">
                {notes}
              </div>
              <div></div>
              <div></div>
            </div>
            <hr className="text-[#FB2306] border-[#FB2306] border-dashed my-4" />
            <h2 className="flex justify-start md:text-[20px] sm:text-[20px] text-[25px] text-[#FB2306] font-metrischbold">
              Your Needs
            </h2>
            <div className="grid grid-cols-4 md:grid-cols-3 sm:grid-cols-2">
              <div className="flex justify-start md:text-[20px] sm:text-[20px] text-[25px] font-metrischbold text-left sm:text-left">
                Ovens
              </div>
              <div className="flex justify-start items-center md:text-[20px] sm:text-[20px] text-[25px] opacity-50 font-metrisch text-left">
                {ov[0]} {ov[1]} {ov[2]} {ov[3]} {ov[4]}
              </div>
              <div></div>
              <div></div>
            </div>
            <div className="grid grid-cols-4 md:grid-cols-3 sm:grid-cols-2">
              <div className="flex justify-start md:text-[20px] sm:text-[20px] text-[25px] font-metrischbold text-left sm:text-left">
                Mixers
              </div>
              <div className="flex justify-start items-center md:text-[20px] sm:text-[20px] text-[25px] opacity-50 font-metrisch text-left">
                {mx[0]} {mx[1]} {mx[2]}
              </div>
              <div></div>
              <div></div>
            </div>
            <div className="grid grid-cols-4 md:grid-cols-3 sm:grid-cols-2">
              <div className="flex justify-start md:text-[20px] sm:text-[20px] text-[25px] font-metrischbold text-left sm:text-left">
                Processors
              </div>
              <div className="flex justify-start items-center md:text-[20px] sm:text-[20px] text-[25px] opacity-50 font-metrisch text-left">
                {pro[0]} {pro[1]} {pro[2]} {pro[3]}
              </div>
              <div></div>
              <div></div>
            </div>
            <div className="grid grid-cols-4 md:grid-cols-3 sm:grid-cols-2">
              <div className="flex justify-start md:text-[20px] sm:text-[20px] text-[25px] font-metrischbold text-left sm:text-left">
                Slicers
              </div>
              <div className="flex justify-start items-center md:text-[20px] sm:text-[20px] text-[25px] opacity-50 font-metrisch text-left">
                {slicers} {bladeSize}
              </div>
              <div></div>
              <div></div>
            </div>
            <div className="grid grid-cols-4 md:grid-cols-3 sm:grid-cols-2">
              <div className="flex justify-start md:text-[20px] sm:text-[20px] text-[25px] font-metrischbold text-left sm:text-left">
                Microwave
              </div>
              <div className="flex justify-start items-center md:text-[20px] sm:text-[20px] text-[25px] opacity-50 font-metrisch text-left">
                {microwave}
              </div>
              <div></div>
              <div></div>
            </div>
            <div className="grid grid-cols-4 md:grid-cols-3 sm:grid-cols-2">
              <div className="flex justify-start md:text-[20px] sm:text-[20px] text-[25px] font-metrischbold text-left sm:text-left">
                Ice Maker
              </div>
              <div className="flex justify-start items-center md:text-[20px] sm:text-[20px] text-[25px] opacity-50 font-metrisch text-left">
                {ice}
              </div>
              <div></div>
              <div></div>
            </div>
            <div className="grid grid-cols-4 md:grid-cols-3 sm:grid-cols-2">
              <div className="flex justify-start md:text-[20px] sm:text-[20px] text-[25px] font-metrischbold text-left sm:text-left">
                Grills
              </div>
              <div className="flex justify-start items-center md:text-[20px] sm:text-[20px] text-[25px] opacity-50 font-metrisch text-left">
                {grl[0]} {grl[1]}
              </div>
              <div></div>
              <div></div>
            </div>
            <div className="grid grid-cols-4 md:grid-cols-3 sm:grid-cols-2">
              <div className="flex justify-start md:text-[20px] sm:text-[20px] text-[25px] font-metrischbold text-left sm:text-left">
                Juicers
              </div>
              <div className="flex justify-start items-center md:text-[20px] sm:text-[20px] text-[25px] opacity-50 font-metrisch text-left">
                {juc[0]} {juc[1]} {juc[2]} {juc[3]} {juc[4]} {juc[5]} {juc[6]}
                {juc[7]}
              </div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div className="flex justify-start pl-4">
            <div className="flex">
              <Checkbox
                // icon={<RadioButtonUncheckedSharpIcon />}
                // checkedIcon={<RadioButtonCheckedSharpIcon />}
                onChange={handleCheck("TnC")}
                sx={{
                  color: "#FB2306",
                  "&.Mui-checked": {
                    color: "#FB2306",
                  },
                }}
              />

              <p className="font-metrisch flex items-center sm:justify-between sm:text-[15px]">
                Please agree to the{" "}
                <button
                  onClick={this.handleOpenT}
                  className="text-[#FB2306] cursor-pointer hover:font-metrischbold"
                >
                  {" "}
                  Terms And Conditions
                </button>
                <Modal open={this.state.openT} onClose={this.handleCloseT}>
                  <Box sx={style}>
                    <div className="flex items-center justify-end">
                      <button onClick={this.handleCloseT}>
                        <CloseIcon />
                      </button>
                    </div>
                    <div className="overflow-visible">
                      <p className="font-metrisch">
                        Partner A is the Brand or concept onboarding on the
                        platform to become a GHOST <br />
                        Partner B is " Host&Ghost" <br />
                        <br />
                        A. Parter A Roles and Responsibilities shall include :{" "}
                        <br />• Partner A will onboard using the platform or
                        through the Account Manager who will gather the required
                        info (refer to image below this section including the
                        required info) <br />• Partner A agrees to help Partner
                        B better understand their operation to find the best
                        match of another Host <br />• Partner A will be
                        participating in the early bird matches in order to
                        better understand the behavior of the partnership <br />
                        • Partner A will conduct few conference calls with the
                        Account manager in which the latter will provide all the
                        required info for the Host in order to start the
                        training phase <br />• Partner A information provided
                        will be shared with the Host to train their staff and
                        prepare the required setup so that once the training
                        phase is done, sales can be initiated and the Host
                        operation is ready <br />• Partner A’s recipe will be
                        safe in which the Host is not allowed to copy, steal, or
                        share the recipe with any other party. This will
                        guarantee the confidentiality and non-discloser of the
                        recipe <br />
                        • Partner A agrees that partner B to be their
                        representative in the country of operation of the
                        matched Host <br />
                        <br />
                        <br />
                        Partner B Roles and Responsibilities shall include:{" "}
                        <br />
                        • Partner B will match Partner A with a Host that has
                        the same material and equipment that can fulfill those
                        requirements <br />• Partner B will match a Host that
                        falls within the knowhow and the food category of
                        Partner A <br />• Partner B will be the only point of
                        contact with Partner A. Thus the Host cannot communicate
                        with the partner A without the account manager provided
                        from Partner B <br />• Partner B will setup all the
                        technical requirements for Partner A (portal, account,
                        admin panel..) <br />• Partner B will provide access to
                        sales report so Partner A can check on daily bases{" "}
                        <br />• Partner B will provide partner A all the
                        required information for the training phase. <br />
                        <br />
                        Parties agree to uphold their roles and responsibilities
                        in a committed, good-faith manner. <br />
                        <br />
                        B. Resources <br />
                        <br />
                        To further the business relationship between the
                        Parties, the Parties agree to provide the following
                        resources.
                        <br />
                        <br />
                        Party A shall provide: <br />• At lease one personnel to
                        communicate with the account manager from Host&Ghost{" "}
                        <br />• Access recipe, menu, SOPs that are required for
                        the training phase. <br />• One team member who might be
                        included in the training process of the Host staff{" "}
                        <br />• Announce their presence in the new location on
                        social media platforms they are available on <br />
                        <br />
                        Party B shall provide: <br />• Access to submission of
                        required information & SOPs for conducting the training{" "}
                        <br />• Access to Daily sales reports <br />
                        <br />
                        Parties agree to provide the resources above at a
                        minimum. The Parties may agree to provide additional
                        resources in future agreements. <br />
                        <br />
                        C. Confidentiality <br />
                        <br />
                        Parties agree that they each use confidential, sensitive
                        information to achieve their individual business goals.
                        Due to the nature of the Parties' businesses and intent
                        to establish a working relationship together,
                        confidential information may be shared between the
                        Parties. <br />
                        <br />
                        The Parties agree to keep all confidential information
                        and trade secrets in the strictest of confidence during
                        the working relationship. Parties may not share any
                        disclosed confidential information with unauthorized
                        third parties. Parties may, at their discretion, enter
                        into a Non-Disclosure Agreement to ensure sensitive
                        information and trade secrets are kept confidential.
                      </p>
                    </div>
                  </Box>
                </Modal>
              </p>
            </div>
          </div>
          <div className="grid grid-rows-1 grid-cols-2 px-[2rem] pb-10 mt-[2.8rem] sm:mt-4 sm:mx-4 md:mx-4">
            <div>
              <button
                className="flex justify-end content-start text-[#cccccc] font-metrischbold "
                onClick={this.back}
              >
                <img
                  className="mr-2"
                  src={Arrow1}
                  width="22px"
                  height="22px"
                  alt=" "
                />
                Back
              </button>
            </div>
            <div className="grid justify-end">
              <button
                ref={this.subRef}
                className="flex justify-end content-start font-metrischbold "
                onClick={(e) => {
                  this.submit(e);
                }}
              >
                Confirm Summary
                <img
                  className="ml-2    "
                  src={Arrow}
                  width="22px"
                  height="22px"
                  alt=" "
                />
              </button>
            </div>
          </div>
        </Fragment>
      </div>
    );
  }
}

export default Summary;
