import React, { Component, Fragment } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import logo from "../imgs/Group 2.png";
import { Link } from "react-router-dom";
import Img1 from "../imgs/Group 32.png";
import Arrow from "../imgs/Icon awesome-arrow-right.png";
import Arrow1 from "../imgs/Icon awesome-arrow-left.png";
import {
  createTheme,
  CssBaseline,
  InputAdornment,
  TextField,
  ThemeProvider,
} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import LanguageRoundedIcon from "@mui/icons-material/LanguageRounded";
import Metrisch from "../fonts/Metrisch-Medium-webfont.woff2";

const theme = createTheme({
  typography: {
    fontFamily: "Metrisch",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: 'Metrisch';
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src: local('Metrisch'), local('Metrisch'), url(${Metrisch}) format('woff2');
        unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
      }
      `,
    },
  },
});
class Step2 extends Component {
  stepValidation = () => {
    const {
      values: { Instagram, Facebook, website },
    } = this.props;
    let isValid = true;
    if (Instagram === "" && Facebook === "" && website === "") {
      alert("Please input at least 1 social media link");
      isValid = false;
    }
    return isValid;
  };
  continue = (e) => {
    e.preventDefault();
    let isValid = this.stepValidation();
    if (isValid) {
      this.props.nextStep();
    }
  };
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
    };
  }
  render() {
    const { values, handleChange } = this.props;
    return (
      <div>
        {/* Header */}
        <div>
          <nav>
            <div className="sm:flex w-full grid grid-rows-1 grid-cols-6 sm:grid-cols-5 content-center items-center">
              <div className="sm:w-full  sm:px-2 w-50 py-4 pl-16">
                <Link to="/">
                  <img src={logo} alt="Host & Ghost" className="w-20 sm:w-14" />
                </Link>
              </div>
              <div className="sm:hidden"></div>
              <div className="sm:hidden flex items-center content-center">
                <span className="bg-[#FB2306] lg:text-[15px] lg:w-[2.5rem] lg:h-[1.5rem] w-7 h-7 rounded-full grid items-center text-white text-[20px] font-metrischbold">
                  1
                </span>
                <span className="grid w-[124pt] lg:text-[15px] text-[20px] font-metrischbold text-left pl-2">
                  About You
                </span>
              </div>
              <div className="flex items-center content-center w-60">
                <span className="bg-[#FB2306] lg:text-[15px] lg:w-6 lg:h-6 sm:w-5 sm:h-5 w-7 h-7 rounded-full grid items-center text-white sm:text-[14px] text-[20px] font-metrischbold">
                  2
                </span>
                <span className="grid w-[124pt] lg:text-[15px] lg:w-[6rem] sm:w-max sm:text-[14px] text-[20px] font-metrischbold text-left sm:pl-1 pl-2 sm:mr-4">
                  About Your Business
                </span>
              </div>
              <div className="sm:hidden flex items-center content-center">
                <span className="bg-[#999999] lg:text-[15px]  lg:w-[2.5rem] lg:h-[1.5rem] w-7 h-7 rounded-full grid items-center text-white text-[20px] font-metrischbold">
                  3
                </span>
                <span className="sm:hidden grid w-[124pt] lg:text-[15px] text-[#999999] text-[20px] font-metrischbold text-left pl-2">
                  Your Goals
                </span>
              </div>
              <div className="sm:hidden flex items-center content-center">
                <span className="bg-[#999999] lg:text-[15px] lg:w-[2.5rem] lg:h-[1.5rem] w-7 h-7 rounded-full grid items-center text-white text-[20px] font-metrischbold">
                  4
                </span>
                <span className="grid w-[124pt] lg:text-[15px] text-[#999999] text-[20px] font-metrischbold text-left pl-2">
                  Your Needs
                </span>
              </div>
            </div>
          </nav>
        </div>
        <Fragment>
          <div className="w-full h-full grid">
            <div className="">
              <ProgressBar
                bgColor="#FB2306"
                baseBgColor="#f7f7f7"
                initCompletedOnAnimation={37.5}
                // animateOnRender={true}
                completed={50}
                isLabelVisible={false}
                height="15px"
              />
            </div>
            <div className="grid sm:grid-cols-1 grid-cols-2 h-full">
              <div className="sm:grid content-center hidden">
                <img className="px-8 mt-4" src={Img1} alt=" " />
              </div>
              <div className="grid content-center sm:py-20 py-10 h-[100vh] lg:px-4 sm:h-full sm:px-4 px-16 -mb-20 shadow-[10px_0_10px_-7px_rgba(251,35,6,0.3)] ">
                <h2 className="flex justify-start sm:text-[30px] sm:justify-center text-[50px] font-metrischextrabold -mb-4 ">
                  Your Social Links
                </h2>
                <h2 className="flex justify-start sm:text-[20px] text-[25px] sm:justify-center text-[#FB2306] font-metrischbold">
                  Business Information
                </h2>
                <h2 className="flex justify-start sm:text-[14px] text-[15px] opacity-50 sm:justify-center font-metrischbold">
                  Provide Your Social Links
                </h2>
                <div className="grid">
                  <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <TextField
                      defaultValue={values.Instagram}
                      onChange={handleChange("Instagram")}
                      sx={{
                        fontFamily: "Metrisch",
                        border: 2,
                        borderRadius: 2,
                        marginTop: 3,
                        "&:hover": {
                          borderColor: "#FB2306",
                        },
                        "&:active": {
                          borderColor: "#FB2306",
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <InstagramIcon sx={{ color: "#FB2306" }} />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Instagram Link"
                    />

                    <TextField
                      defaultValue={values.Facebook}
                      onChange={handleChange("Facebook")}
                      sx={{
                        fontFamily: "Metrisch",
                        border: 2,
                        borderRadius: 2,
                        marginTop: 3,
                        "&:hover": {
                          borderColor: "#FB2306",
                        },
                        "&:active": {
                          borderColor: "#FB2306",
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FacebookOutlinedIcon sx={{ color: "#FB2306" }} />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Facebook Link"
                    />
                    <TextField
                      defaultValue={values.website}
                      onChange={handleChange("website")}
                      sx={{
                        fontFamily: "Metrisch",
                        border: 2,
                        borderRadius: 2,
                        marginTop: 3,
                        "&:hover": {
                          borderColor: "#FB2306",
                        },
                        "&:active": {
                          borderColor: "#FB2306",
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LanguageRoundedIcon sx={{ color: "#FB2306" }} />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Website Link"
                    />
                  </ThemeProvider>
                </div>
                <div className="hidden lg:grid sm:hidden">
                  <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <TextField
                      defaultValue={values.Instagram}
                      onChange={handleChange("Instagram")}
                      sx={{
                        fontFamily: "Metrisch",
                        border: 2,
                        borderRadius: 2,
                        marginTop: 3,
                        "&:hover": {
                          borderColor: "#FB2306",
                        },
                        "&:active": {
                          borderColor: "#FB2306",
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <InstagramIcon sx={{ color: "#FB2306" }} />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Instagram Link"
                    />

                    <TextField
                      defaultValue={values.Facebook}
                      onChange={handleChange("Facebook")}
                      sx={{
                        fontFamily: "Metrisch",
                        border: 2,
                        borderRadius: 2,
                        marginTop: 3,
                        "&:hover": {
                          borderColor: "#FB2306",
                        },
                        "&:active": {
                          borderColor: "#FB2306",
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FacebookOutlinedIcon sx={{ color: "#FB2306" }} />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Facebook Link"
                    />
                    <TextField
                      defaultValue={values.website}
                      onChange={handleChange("website")}
                      sx={{
                        fontFamily: "Metrisch",
                        border: 2,
                        borderRadius: 2,
                        marginTop: 3,
                        "&:hover": {
                          borderColor: "#FB2306",
                        },
                        "&:active": {
                          borderColor: "#FB2306",
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LanguageRoundedIcon sx={{ color: "#FB2306" }} />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Website Link"
                    />
                  </ThemeProvider>
                </div>
                <br />
                <div className="grid grid-rows-1 grid-cols-2 mt-[19.35rem] sm:mt-4 lg:mt-8 xl:mt-0">
                  <div>
                    <button
                      className="flex justify-end content-start text-[#cccccc] font-metrischbold "
                      onClick={this.back}
                    >
                      <img
                        className="mr-2    "
                        src={Arrow1}
                        width="22px"
                        height="22px"
                        alt=" "
                      />
                      Back
                    </button>
                  </div>
                  <div className="flex justify-end">
                    <button
                      className="flex justify-end content-start font-metrischbold "
                      onClick={this.continue}
                    >
                      Continue
                      <img
                        className="ml-2"
                        src={Arrow}
                        width="22px"
                        height="22px"
                        alt=" "
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div className="grid content-center sm:hidden">
                <img
                  className="px-8 ml-[5rem] lg:ml-0 xl:m-0"
                  src={Img1}
                  alt=" "
                />
              </div>

              <br />
            </div>
          </div>
        </Fragment>
      </div>
    );
  }
}

export default Step2;
