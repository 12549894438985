import React from "react";
import Header from "./Header";
import dude from "../imgs/Group 135.png";
import sushi from "../imgs/Group 80.png";
import burger from "../imgs/Group 78.png";
import ghost from "../imgs/Group 100.png";
import onBoard from "../imgs/Group 145.png";
import approve from "../imgs/Group 151.png";
import deal from "../imgs/Group 138.png";
import chart from "../imgs/How it works.png";
import bread from "../imgs/Group 125.png";
// import process from "../imgs/Group 130.png";
import Footer from "./Footer";
import { Link } from "react-router-dom";

function HowItWorks() {
	return (
		<>
			<Header />
			<div className="p-8 mb-[10rem] justify-items-center grid">
				<h1 className=" font-metrischbold text-[#FB2306] text-[40px] mt-16 mb-[5rem] text-center sm:text-[30px] ">
					Become A Ghost
				</h1>
				<div className="flex justify-between w-[90%] px-60 mt-8 sm:px-4 sm:grid sm:justify-center xl:px-8 lg:px-0 lg:mb-8">
					<div className="border-[#FB2306] w-60 h-60 p-4 shadow-foot1 rounded-lg sm:mb-[7rem] sm:w-52 sm:h-52 lg:w-32 lg:h-32">
						<h2 className="text-center text-[70px] font-metrischextrabold -mt-[4.5rem] sm:text-[60px] lg:text-[60px]">
							01
						</h2>
						<img
							className="w-40 h-40 ml-8 sm:w-32 sm:h-32 lg:w-20 lg:h-20 lg:ml-4 lg:-mt-2"
							src={onBoard}
							alt=""
						/>
						<p className="text-center font-metrischextrabold text-[20px] mt-[3rem] sm:mt-[4rem] lg:mt-8">Onboard</p>
					</div>
					<div className="border-[#FB2306] w-60 h-60 p-4 shadow-foot1 rounded-lg sm:mb-[7rem] sm:w-52 sm:h-52 lg:w-32 lg:h-32">
						<h2 className="text-center text-[70px] font-metrischextrabold -mt-[4.5rem] sm:text-[60px] lg:text-[60px]">
							02
						</h2>
						<img
							className="w-40 h-44 ml-4 sm:w-32 sm:h-36 lg:w-20 lg:h-24 lg:ml-2 lg:-mt-4"
							src={approve}
							alt=""
						/>
						<p className="text-center font-metrischextrabold text-[20px] mt-[2.2rem] sm:mt-[4rem] lg:mt-6">
							Get approved
						</p>
					</div>
					<div className="border-[#FB2306] w-60 h-60 p-4 shadow-foot1 rounded-lg sm:mb-[9rem] sm:w-52 sm:h-52 lg:w-32 lg:h-32">
						<h2 className="text-center text-[70px] font-metrischextrabold -mt-[4.5rem] sm:text-[60px] lg:text-[60px]">
							03
						</h2>
						<img
							className="w-40 h-40 ml-8 sm:w-32 sm:h-32 lg:w-20 lg:h-20 lg:ml-4 "
							src={deal}
							alt=""
						/>
						<p className="text-center font-metrischextrabold text-[20px] mt-[3rem] sm:mt-[4rem] lg:mt-6">
							Deal with paperwork
						</p>
					</div>
					<div className="border-[#FB2306] w-60 h-60 shadow-foot1 rounded-lg sm:mb-[7rem] sm:w-52 sm:h-52 lg:w-32 lg:h-32">
						<h2 className="text-center text-[70px] font-metrischextrabold -mt-[3.5rem] sm:text-[60px] lg:text-[60px]">
							04
						</h2>
						<img
							className="w-52 h-52 ml-8 -mt-4 sm:w-44 sm:h-44 sm:mt-0 lg:w-28 lg:h-28 lg:-mt-4 lg:ml-4"
							src={dude}
							alt=""
						/>
						<p className="text-center font-metrischextrabold text-[20px] mt-[1.2rem] lg:mt-2">Haunt your first host</p>
					</div>
				</div>
				<div>
					<div>
						<Link
							className=" hover:text-white font-metrischbold flex justify-start mt-16 sm:mt-0 "
							to={"/register-ghost"}>
							<button className=" p-2 text-[20px] border-[3px] rounded-[6px] border-[#FB2306] text-[#FB2306] hover:bg-[#FB2306] hover:text-white">
								Become A Ghost
							</button>
						</Link>
					</div>
				</div>
				<div className="flex justify-between w-[90%] justify-self-center mt-24">
					<div>
						<img
							src={sushi}
							alt=""
							className="lg:w-44 lg:h-44 w-1/2"
						/>
					</div>
					<div>
						<img
							src={burger}
							alt=""
							className="sm:hidden w-1/2 pl-16 lg:pl-0 lg:w-44 lg:h-44"
						/>
					</div>
				</div>
				<h1 className=" font-metrischbold text-[#FB2306] text-[40px] -mt-10 mb-[6rem] text-center sm:mt-0 ">
					Become A Host
				</h1>
				<div className="flex justify-between w-[90%] px-60 mt-8 sm:px-4 sm:grid sm:justify-center xl:px-8 lg:px-0 lg:mb-8">
					<div className="border-[#FB2306] w-60 h-60 p-4 shadow-foot1 rounded-lg sm:mb-[7rem] sm:w-52 sm:h-52 lg:w-32 lg:h-32">
						<h2 className="text-center text-[70px] font-metrischextrabold -mt-[4.5rem] sm:text-[60px] lg:text-[60px]">
							01
						</h2>
						<img
							className="w-40 h-40 ml-8 sm:w-32 sm:h-32 lg:w-20 lg:h-20 lg:ml-4 lg:-mt-2"
							src={onBoard}
							alt=""
						/>
						<p className="text-center font-metrischextrabold text-[20px] mt-[3rem] sm:mt-[4rem] lg:mt-8">Onboard</p>
					</div>
					<div className="border-[#FB2306] w-60 h-60 p-4 shadow-foot1 rounded-lg sm:mb-[7rem] sm:w-52 sm:h-52 lg:w-32 lg:h-32">
						<h2 className="text-center text-[70px] font-metrischextrabold -mt-[4.5rem] sm:text-[60px] lg:text-[60px]">
							02
						</h2>
						<img
							className="w-40 h-44 ml-4 sm:w-32 sm:h-36 lg:w-20 lg:h-24 lg:ml-2 lg:-mt-4"
							src={approve}
							alt=""
						/>
						<p className="text-center font-metrischextrabold text-[20px] mt-[2.2rem] sm:mt-[4rem] lg:mt-6">
							Get approved
						</p>
					</div>
					<div className="border-[#FB2306] w-60 h-60 p-4 shadow-foot1 rounded-lg sm:mb-[9rem] sm:w-52 sm:h-52 lg:w-32 lg:h-32">
						<h2 className="text-center text-[70px] font-metrischextrabold -mt-[4.5rem] sm:text-[60px] lg:text-[60px]">
							03
						</h2>
						<img
							className="w-40 h-40 ml-8 sm:w-32 sm:h-32 lg:w-20 lg:h-20 lg:ml-4 "
							src={deal}
							alt=""
						/>
						<p className="text-center font-metrischextrabold text-[20px] mt-[3rem] sm:mt-[4rem] lg:mt-6">
							Deal with paperwork
						</p>
					</div>
					<div className="border-[#FB2306] w-60 h-60 shadow-foot1 rounded-lg sm:mb-[7rem] sm:w-52 sm:h-52 lg:w-32 lg:h-32">
						<h2 className="text-center text-[70px] font-metrischextrabold -mt-[3.5rem] sm:text-[60px] lg:text-[60px]">
							04
						</h2>
						<img
							className="w-52 h-52 ml-4 -mt-8 sm:w-44 sm:h-44 sm:mt-0 lg:w-24 lg:h-24 lg:-mt-4 lg:ml-4"
							src={ghost}
							alt=""
						/>
						<p className="text-center font-metrischextrabold text-[20px] mt-[2rem] lg:mt-6">Catch your first ghost</p>
					</div>
				</div>
				<div>
					<div>
						<Link
							className=" hover:text-white font-metrischbold flex justify-start mt-16 sm:mt-0 "
							to={"/register-host"}>
							<button className=" p-2 text-[20px] border-[3px] rounded-[6px] border-[#FB2306] text-[#FB2306] hover:bg-[#FB2306] hover:text-white">
								Become A Host
							</button>
						</Link>
					</div>
				</div>
				<div className="mt-[10rem] grid justify-center">
					<h1 className=" font-metrischbold text-[#FB2306] text-[40px] mt-8 mb-[7rem] text-center sm:mb-8">
						How it works
					</h1>
					<img
						src={chart}
						alt=""
					/>
					{/* <img src={process} alt="" className="hidden sm:grid" /> */}
				</div>
				<h1 className=" font-metrischbold text-[#FB2306] text-[40px] mt-12 mb-[1rem] text-left sm:ml-4 lg:ml-0">
					Start Now
				</h1>
				<div className="grid grid-cols-3 gap-4 px-60 my-8 sm:grid sm:grid-cols-1 sm:justify-items-center sm:px-4 sm:justify-center xl:px-8 lg:px-0 lg:grid-cols-2 lg:">
					{/* <div className="border-[#FB2306] w-72 pt-6 pb-10 px-14 shadow-foot1 rounded-lg sm:mb-[6rem] lg:p-4 lg:w-auto lg:mr-4 mx-4 lg:mx-0 hover:scale-110 xl:hover:scale-100">
						<h3 className="font-metrischextrabold text-[24px] text-[#FB2306] text-left">
							Early Bird <span className="text-black">Package</span>
						</h3>
						<h3 className="font-metrischextrabold text-[15px] text-black text-left mt-4">
							Onboarding <span className="text-[#FB2306]">*</span>
						</h3>
						<h3 className="font-metrischextrabold text-[15px] text-[#FB2306] text-left">$500</h3>
						<hr className="border-[#FB2306] border-dashed mt-4" />
						<h3 className="font-metrischextrabold text-[15px] text-black text-left mt-2">
							Service Fee <span className="text-[#FB2306]">*</span>
						</h3>
						<h3 className="font-metrischextrabold text-[15px] text-[#FB2306] text-left">$200</h3>
						<hr className="border-[#FB2306] border-dashed mt-4" />
						<h3 className="font-metrischextrabold text-[15px] text-black text-left mt-2">
							Packaging <span className="text-[#FB2306]">*</span>
						</h3>
						<h3 className="font-metrischextrabold text-[15px] text-[#FB2306] text-left">H&G Packaging</h3>
						<hr className="border-[#FB2306] border-dashed mt-4" />
						<h3 className="font-metrischextrabold text-[15px] text-black text-left mt-2">
							Order % Fee <span className="text-[#FB2306]">*</span>
						</h3>
						<h3 className="font-metrischextrabold text-[15px] text-[#FB2306] text-left">0%</h3>
						<Link
							className=" hover:text-white font-metrischbold flex justify-start mt-8 "
							to={"/register-ghost"}>
							<button className=" p-1 border-[3px] rounded-[6px] border-[#FB2306] text-[#FB2306] hover:bg-[#FB2306] hover:text-white">
								Become A Ghost
							</button>
						</Link>
					</div>
					<div className="border-[#FB2306] w-72 pt-6 pb-10 px-14 shadow-foot1 rounded-lg sm:mb-[6rem] lg:p-4 lg:w-auto lg:mr-4 mx-4 lg:mx-0 hover:scale-110 xl:hover:scale-100">
						<h3 className="font-metrischextrabold text-[24px] text-left text-[#FB2306]">
							Basic <br className="lg:hidden" />
							<span className="text-black">Package</span>
						</h3>
						<h3 className="font-metrischextrabold text-[15px] text-black text-left mt-4">
							Onboarding <span className="text-[#FB2306]">*</span>
						</h3>
						<h3 className="font-metrischextrabold text-[15px] text-[#FB2306] text-left">$1,500</h3>
						<hr className="border-[#FB2306] border-dashed mt-4" />
						<h3 className="font-metrischextrabold text-[15px] text-black text-left mt-2">
							Service Fee <span className="text-[#FB2306]">*</span>
						</h3>
						<h3 className="font-metrischextrabold text-[15px] text-[#FB2306] text-left">$800</h3>
						<hr className="border-[#FB2306] border-dashed mt-4" />
						<h3 className="font-metrischextrabold text-[15px] text-black text-left mt-2">
							Packaging <span className="text-[#FB2306]">*</span>
						</h3>
						<h3 className="font-metrischextrabold text-[15px] text-[#FB2306] text-left">H&G Packaging</h3>
						<hr className="border-[#FB2306] border-dashed mt-4" />
						<h3 className="font-metrischextrabold text-[15px] text-black text-left mt-2">
							Order % Fee <span className="text-[#FB2306]">*</span>
						</h3>
						<h3 className="font-metrischextrabold text-[15px] text-[#FB2306] text-left">4% of total revenue</h3>
						<Link
							className=" hover:text-white font-metrischbold flex justify-start mt-8 "
							to={"/register-ghost"}>
							<button className=" p-1 border-[3px] rounded-[6px] border-[#FB2306] text-[#FB2306] hover:bg-[#FB2306] hover:text-white">
								Become A Ghost
							</button>
						</Link>
					</div>
					<div className="border-[#FB2306] w-72 pt-6 pb-10 px-14 shadow-foot1 rounded-lg sm:mb-[6rem] lg:p-4 lg:w-auto lg:mr-4 mx-4 lg:mx-0 hover:scale-110 xl:hover:scale-100">
						<h3 className="font-metrischextrabold text-[24px] text-left text-[#FB2306]">
							Advanced <span className="text-black">Package</span>
						</h3>
						<h3 className="font-metrischextrabold text-[15px] text-black text-left mt-4">
							Onboarding <span className="text-[#FB2306]">*</span>
						</h3>
						<h3 className="font-metrischextrabold text-[15px] text-[#FB2306] text-left">$2,000</h3>
						<hr className="border-[#FB2306] border-dashed mt-4" />
						<h3 className="font-metrischextrabold text-[15px] text-black text-left mt-2">
							Service Fee <span className="text-[#FB2306]">*</span>
						</h3>
						<h3 className="font-metrischextrabold text-[15px] text-[#FB2306] text-left">$950</h3>
						<hr className="border-[#FB2306] border-dashed mt-4" />
						<h3 className="font-metrischextrabold text-[15px] text-black text-left mt-2">
							Packaging <span className="text-[#FB2306]">*</span>
						</h3>
						<h3 className="font-metrischextrabold text-[15px] text-[#FB2306] text-left">Basic Packaging</h3>
						<hr className="border-[#FB2306] border-dashed mt-4" />
						<h3 className="font-metrischextrabold text-[15px] text-black text-left mt-2">
							Order % Fee <span className="text-[#FB2306]">*</span>
						</h3>
						<h3 className="font-metrischextrabold text-[15px] text-[#FB2306] text-left">4% of total revenue</h3>
						<Link
							className=" hover:text-white font-metrischbold flex justify-start mt-8 "
							to={"/register-ghost"}>
							<button className=" p-1 border-[3px] rounded-[6px] border-[#FB2306] text-[#FB2306] hover:bg-[#FB2306] hover:text-white">
								Become A Ghost
							</button>
						</Link>
					</div>
					<div className="border-[#FB2306] w-72 pt-6 pb-10 px-14 shadow-foot1 rounded-lg sm:mb-[2rem] lg:p-4 lg:w-auto mx-4 lg:mx-0 hover:scale-110 xl:hover:scale-100">
						<h3 className="font-metrischextrabold text-[24px] text-left text-[#FB2306]">
							Premium <span className="text-black">Package</span>
						</h3>
						<h3 className="font-metrischextrabold text-[15px] text-black text-left mt-4">
							Onboarding <span className="text-[#FB2306]">*</span>
						</h3>
						<h3 className="font-metrischextrabold text-[15px] text-[#FB2306] text-left">$2,500</h3>
						<hr className="border-[#FB2306] border-dashed mt-4" />
						<h3 className="font-metrischextrabold text-[15px] text-black text-left mt-2">
							Service Fee <span className="text-[#FB2306]">*</span>
						</h3>
						<h3 className="font-metrischextrabold text-[15px] text-[#FB2306] text-left">$1,150</h3>
						<hr className="border-[#FB2306] border-dashed mt-4" />
						<h3 className="font-metrischextrabold text-[15px] text-black text-left mt-2">
							Packaging <span className="text-[#FB2306]">*</span>
						</h3>
						<h3 className="font-metrischextrabold text-[15px] text-[#FB2306] text-left">Advanced Packaging</h3>
						<hr className="border-[#FB2306] border-dashed mt-4" />
						<h3 className="font-metrischextrabold text-[15px] text-black text-left mt-2">
							Order % Fee <span className="text-[#FB2306]">*</span>
						</h3>
						<h3 className="font-metrischextrabold text-[15px] text-[#FB2306] text-left">4% of total revenue</h3>
						<Link
							className=" hover:text-white font-metrischbold flex justify-start mt-8 "
							to={"/register-ghost"}>
							<button className=" p-1 border-[3px] rounded-[6px] border-[#FB2306] text-[#FB2306] hover:bg-[#FB2306] hover:text-white">
								Become A Ghost
							</button>
						</Link>
					</div> */}
					<div className="border-[#FB2306] w-72 pt-6 pb-10 px-14 shadow-foot1 rounded-lg sm:mb-[2rem] lg:p-4 lg:w-auto mx-4 lg:mx-0 hover:scale-110 xl:hover:scale-100">
						{/* <h3 className="font-metrischextrabold text-[21px] text-left text-[#FB2306]">Onboarding Fee</h3> */}
						<h3 className="font-metrischextrabold text-[15px] text-black text-left mt-4">Onboarding Fee</h3>
						<h3 className="font-metrischextrabold text-[15px] text-[#FB2306] text-left">*One Time Payment</h3>
						{/* <hr className="border-[#FB2306] border-dashed mt-4" />
						<h3 className="font-metrischextrabold text-[15px] text-black text-left mt-2">Service Fee</h3>
						<h3 className="font-metrischextrabold text-[15px] text-[#FB2306] text-left">*Marketing, Quality Control</h3>
						<hr className="border-[#FB2306] border-dashed mt-4" />
						<h3 className="font-metrischextrabold text-[15px] text-black text-left mt-2">5% Fee</h3>
						<h3 className="font-metrischextrabold text-[15px] text-[#FB2306] text-left">*Per Order</h3> */}
						{/* <hr className="border-[#FB2306] border-dashed mt-4" /> */}
						{/* <h3 className="font-metrischextrabold text-[15px] text-black text-left mt-2">
							Order % Fee <spFan className="text-[#FB2306]">*</spFan>
						</h3>
						<h3 className="font-metrischextrabold text-[15px] text-[#FB2306] text-left">4% of total revenue</h3> */}
						{/* <Link
							className=" hover:text-white font-metrischbold flex justify-start mt-8 "
							to={"/register-ghost"}>
							<button className=" p-1 border-[3px] rounded-[6px] border-[#FB2306] text-[#FB2306] hover:bg-[#FB2306] hover:text-white">
								Become A Ghost
							</button>
						</Link> */}
					</div>
					<div className="border-[#FB2306] w-72 pt-6 pb-10 px-14 shadow-foot1 rounded-lg sm:mb-[2rem] lg:p-4 lg:w-auto mx-4 lg:mx-0 hover:scale-110 xl:hover:scale-100">
						{/* <h3 className="font-metrischextrabold text-[21px] text-left text-[#FB2306]">Onboarding Fee</h3> */}
						{/* <h3 className="font-metrischextrabold text-[15px] text-black text-left mt-4">Onboarding Fee</h3>
						<h3 className="font-metrischextrabold text-[15px] text-[#FB2306] text-left">*One Time Payment</h3>
						<hr className="border-[#FB2306] border-dashed mt-4" /> */}
						<h3 className="font-metrischextrabold text-[15px] text-black text-left mt-2">Service Fee</h3>
						<h3 className="font-metrischextrabold text-[15px] text-[#FB2306] text-left">*Marketing, Quality Control</h3>
						{/* <hr className="border-[#FB2306] border-dashed mt-4" />
						<h3 className="font-metrischextrabold text-[15px] text-black text-left mt-2">5% Fee</h3>
						<h3 className="font-metrischextrabold text-[15px] text-[#FB2306] text-left">*Per Order</h3> */}
						{/* <hr className="border-[#FB2306] border-dashed mt-4" /> */}
						{/* <h3 className="font-metrischextrabold text-[15px] text-black text-left mt-2">
							Order % Fee <spFan className="text-[#FB2306]">*</spFan>
						</h3>
						<h3 className="font-metrischextrabold text-[15px] text-[#FB2306] text-left">4% of total revenue</h3> */}
						{/* <Link
							className=" hover:text-white font-metrischbold flex justify-start mt-8 "
							to={"/register-ghost"}>
							<button className=" p-1 border-[3px] rounded-[6px] border-[#FB2306] text-[#FB2306] hover:bg-[#FB2306] hover:text-white">
								Become A Ghost
							</button>
						</Link> */}
					</div>
					<div className="border-[#FB2306] w-72 pt-6 pb-10 px-14 shadow-foot1 rounded-lg sm:mb-[2rem] lg:p-4 lg:w-auto mx-4 lg:mx-0 hover:scale-110 xl:hover:scale-100">
						{/* <h3 className="font-metrischextrabold text-[21px] text-left text-[#FB2306]">Onboarding Fee</h3> */}
						{/* <h3 className="font-metrischextrabold text-[15px] text-black text-left mt-4">Onboarding Fee</h3>
						<h3 className="font-metrischextrabold text-[15px] text-[#FB2306] text-left">*One Time Payment</h3>
						 <hr className="border-[#FB2306] border-dashed mt-4" />
						<h3 className="font-metrischextrabold text-[15px] text-black text-left mt-2">Service Fee</h3>
						<h3 className="font-metrischextrabold text-[15px] text-[#FB2306] text-left">*Marketing, Quality Control</h3>
						<hr className="border-[#FB2306] border-dashed mt-4" /> */}
						<h3 className="font-metrischextrabold text-[15px] text-black text-left mt-2">5% Fee</h3>
						<h3 className="font-metrischextrabold text-[15px] text-[#FB2306] text-left">*Per Order</h3>
						{/* <hr className="border-[#FB2306] border-dashed mt-4" /> */}
						{/* <h3 className="font-metrischextrabold text-[15px] text-black text-left mt-2">
							Order % Fee <spFan className="text-[#FB2306]">*</spFan>
						</h3>
						<h3 className="font-metrischextrabold text-[15px] text-[#FB2306] text-left">4% of total revenue</h3> */}
						{/* <Link
							className=" hover:text-white font-metrischbold flex justify-start mt-8 "
							to={"/register-ghost"}>
							<button className=" p-1 border-[3px] rounded-[6px] border-[#FB2306] text-[#FB2306] hover:bg-[#FB2306] hover:text-white">
								Become A Ghost
							</button>
						</Link> */}
					</div>
					<div></div>
					<div className="justify-center flex">
						<Link
							className=" hover:text-white font-metrischbold flex justify-start mt-8 "
							to={"/register-ghost"}>
							<button className=" p-1 border-[3px] rounded-[6px] border-[#FB2306] text-[#FB2306] hover:bg-[#FB2306] hover:text-white">
								Become A Ghost
							</button>
						</Link>
					</div>
					<div></div>
				</div>
				{/* <h1 className=" font-metrischbold text-[#FB2306] text-[13px] mt-12 mb-[1rem]  sm:ml-4 sm:mt-4 text-center lg:ml-0">
					<em>
						*Onboarding process includes the following: The Match, Menu Management, Branch Management, SOP, Sales and
						Performance Report, Dashboard on Ordering Website.
					</em>
				</h1>
				<h1 className=" font-metrischbold text-[#FB2306] text-[13px] mb-[4rem]  sm:ml-4 sm:mt-4 text-center lg:ml-0">
					<em>*Service fee includes the following: Ordering Platform, Marketing, Mystery Shopping.</em>
				</h1> */}
				<div className="flex justify-between items-end sm:mx-4 sm:hidden xl:mx-8 lg:mx-0">
					<h1 className=" font-metrischbold text-[#FB2306] text-[40px] mt-12 mb-[1rem] text-left mr-[30rem] lg:mr-[15rem]">
						Why Join Us?
					</h1>
					<img
						src={bread}
						alt=""
						className="sm:hidden lg:w-52"
					/>
				</div>
				<div className="border-[#FB2306] flex p-8 mt-8 shadow-foot1 rounded-lg sm:mx-4 sm:hidden xl:mx-8 lg:mx-0 lg:p-4">
					<div className="grid grid-rows-6 w-full">
						<div className="grid grid-cols-3 justify-between text-left items-center">
							<div>
								<h3 className="text-[#FB2306] font-metrischbold text-[20px]">Comparison</h3>
							</div>
							<div>
								<h3 className="font-metrisch text-[15px]">
									Brick & Mortar
									<br />
									traditional restaurant
								</h3>
							</div>
							<div>
								<h3 className="font-metrisch text-[15px]">
									Cloud Kitchens
									<br />
									delivery restaurant
								</h3>
							</div>
						</div>
						<div className="grid grid-cols-3 justify-between text-left items-center bg-[#FB2306] bg-opacity-10 rounded-lg p-4 mb-8">
							<div>
								<h3 className="font-metrischextrabold text-[15px]">Capital Investment</h3>
							</div>
							<div>
								<h3 className="font-metrischextrabold text-[15px]">$500K</h3>
							</div>
							<div>
								<h3 className="font-metrischextrabold text-[15px]">$30K</h3>
							</div>
						</div>
						<div className="grid grid-cols-3 justify-between text-left items-center bg-[#FB2306] bg-opacity-10 rounded-lg p-4 mb-8">
							<div>
								<h3 className="font-metrischextrabold text-[15px]">Real estate</h3>
							</div>
							<div>
								<h3 className="font-metrisch text-[15px]">
									50 to 200 m<sup>2</sup>
									<br />
									{/* <span className="text-[#FB2306]">
                    High cost, high traffic address
                  </span> */}
								</h3>
							</div>
							<div>
								<h3 className="font-metrisch text-[15px]">
									10 to 20 m<sup>2</sup>
								</h3>
							</div>
						</div>
						<div className="grid grid-cols-3 justify-between text-left items-center bg-[#FB2306] bg-opacity-10 rounded-lg p-4 mb-8">
							<div>
								<h3 className="font-metrischextrabold text-[15px]">Staff required</h3>
							</div>
							<div>
								<h3 className="font-metrisch text-[15px]">
									25+ employees
									<br />
									{/* <span className="text-[#FB2306]">
                    Many dedicated to front-of-house
                  </span> */}
								</h3>
							</div>
							<div>
								<h3 className="font-metrisch text-[15px]">~4 employees</h3>
							</div>
						</div>
						<div className="grid grid-cols-3 justify-between text-left items-center bg-[#FB2306] bg-opacity-10 rounded-lg p-4 mb-8">
							<div>
								<h3 className="font-metrischextrabold text-[15px]">Time required to open</h3>
							</div>
							<div>
								<h3 className="font-metrisch text-[15px]">
									1 location 52 weeks
									<br />
									{/* <span className="text-[#FB2306]">
                    10 locations 5-10 years
                  </span> */}
								</h3>
							</div>
							<div>
								<h3 className="font-metrisch text-[15px]">1 location ~6 weeks</h3>
							</div>
						</div>
						<div className="grid grid-cols-3 justify-between text-left items-center bg-[#FB2306] bg-opacity-10 rounded-lg p-4 mb-8">
							<div>
								<h3 className="font-metrischextrabold text-[15px]">Operational Involvement</h3>
							</div>
							<div>
								<h3 className="font-metrisch text-[15px]">
									5x
									<br />
									{/* <span className="text-[#FB2306]">
                    10% profit on 1M run rate
                  </span> */}
								</h3>
							</div>
							<div>
								<h3 className="font-metrisch text-[15px]">3x</h3>
							</div>
						</div>
					</div>
					<div className="border-l-4 border-dashed border-[#FB2306] mx-12 mt-[6.5rem]"></div>
					<div className="grid grid-rows-6 w-[20%]">
						<div className="grid grid-cols-1 justify-between text-left items-center">
							<div>
								<h3 className="text-[#FB2306] font-metrischbold text-[20px]">Host & Ghost</h3>
							</div>
						</div>
						<div className="grid grid-cols-1 justify-between text-left items-center bg-[#FB2306] bg-opacity-10 rounded-lg p-4 mb-8">
							<div>
								<h3 className="font-metrischextrabold text-[15px]">$12K</h3>
							</div>
						</div>
						<div className="grid grid-cols-1 justify-between text-left items-center bg-[#FB2306] bg-opacity-10 rounded-lg p-4 mb-8">
							<div>
								<h3 className="font-metrisch text-[15px]">
									0
									<br />
									<span className="text-[#FB2306] text-[13px]">No need for space</span>
								</h3>
							</div>
						</div>
						<div className="grid grid-cols-1 justify-between text-left items-center bg-[#FB2306] bg-opacity-10 rounded-lg p-4 mb-8">
							<div>
								<h3 className="font-metrisch text-[15px]">
									0
									<br />
									<span className="text-[#FB2306] text-[13px]">No need for employees</span>
								</h3>
							</div>
						</div>
						<div className="grid grid-cols-1 justify-between text-left items-center bg-[#FB2306] bg-opacity-10 rounded-lg p-4 mb-8">
							<div>
								<h3 className="font-metrisch text-[15px]">3 week</h3>
							</div>
						</div>
						<div className="grid grid-cols-1 justify-between text-left items-center bg-[#FB2306] bg-opacity-10 rounded-lg p-4 mb-8">
							<div>
								<h3 className="font-metrisch text-[15px]">Free of headache</h3>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
}

export default HowItWorks;
