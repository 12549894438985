import React, { useState } from "react";
import "../css/navbar.css";
import { Link } from "react-router-dom";
import logo from "../imgs/Group 2.png";

const Navbar = () => {
  // to change burger classes
  const [burger_class, setBurgerClass] = useState("burger-bar unclicked");
  const [menu_class, setMenuClass] = useState("menu hidden");
  const [isMenuClicked, setIsMenuClicked] = useState(false);

  // toggle burger menu change
  const updateMenu = () => {
    if (!isMenuClicked) {
      setBurgerClass("burger-bar clicked");
      setMenuClass("menu visible");
    } else {
      setBurgerClass("burger-bar unclicked");
      setMenuClass("menu hidden");
    }
    setIsMenuClicked(!isMenuClicked);
  };

  return (
    <div>
      <nav>
        <div className="absolute left-0">
          <Link to="/">
            <img src={logo} alt="Host & Ghost" className="w-20 ml-10 sm:w-14" />
          </Link>
        </div>
        <div className="burger-menu" onClick={updateMenu}>
          <div className={burger_class}></div>
          <div className={burger_class}></div>
          <div className={burger_class}></div>
        </div>
      </nav>

      <div className={menu_class}>
        <div className="grid grid-cols-1 grid-rows-4 gap-y-8">
          <div>
            <Link
              className="font-metrischbold text-[#000000] lg:text-[30px]"
              to="/how-it-works"
            >
              How It Works
            </Link>
          </div>
          <div>
            <Link
              className="font-metrischbold text-[#000000] lg:text-[30px]"
              to="/about-us"
            >
              About Us
            </Link>
          </div>
          <div>
            <Link
              className=" font-metrischbold text-[#000000] lg:text-[30px]"
              to={"/register-host"}
            >
              For Hosts
            </Link>
          </div>
          <div className="flex justify-center">
            <Link
              className=" hover:text-white font-metrischbold flex justify-end "
              to={"/register-ghost"}
            >
              <button className=" p-1 border-[3px] rounded-[6px] border-[#FB2306] text-[#FB2306] hover:bg-[#FB2306] hover:text-white lg:text-[30px] lg:px-3">
                Become A Ghost
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
