import React, { Component } from "react";
import logo from "../imgs/Group 2.png";
import { Link } from "react-router-dom";
import Email from "../imgs/Icon zocial-email.png";
import Phone from "../imgs/Icon metro-mobile.png";
// import LinkedIn from "../imgs/Icon awesome-linkedin.png";
// import TikTok from "../imgs/Icon simple-tiktok.png";
import Insta from "../imgs/Icon feather-instagram.png";
import Face from "../imgs/Icon metro-facebook.png";

export class Footer extends Component {
  topFunction = (e) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  render() {
    return (
      <div className="grid grid-cols-4 grid-rows-1 p-4 shadow-foot font-metrischbold w-full sm:grid-cols-1 sm:justify-center sm:px-0">
        <div className="sm:justify-center sm:flex sm:m-0 sm:w-full sm:-ml-4">
          <Link to="/">
            <img
              src={logo}
              alt="Host & Ghost"
              className="w-20 ml-10 sm:w-14"
              onClick={this.topFunction}
            />
          </Link>
        </div>
        <div className="flex justify-center items-center sm:justify-center sm:flex sm:mx-4 sm:my-2">
          <a href="mailto:info@hostnghost.com" className="flex">
            <img src={Email} alt="" className="w-8" />
            <p className="px-2">info@hostnghost.com</p>
          </a>
        </div>
        <div className="flex justify-center items-center sm:justify-center sm:flex sm:mx-4 sm:my-2">
          <a href="tel:+965 9698 7929" className="flex">
            <img src={Phone} alt="" className="w-4" />
            <p className="px-2">+965 9698 7929</p>
          </a>
        </div>
        <div className="flex w-[50%] justify-center items-center sm:flex sm:mx-4 sm:my-2 sm:w-[90%] sm:justify-center lg:w-[100%]">
          {/* <a href="awd">
            <img src={LinkedIn} alt="" className="w-6" />
          </a> */}
          <a
            href="https://instagram.com/hostndghost?igshid=YmMyMTA2M2Y="
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Insta} alt="" className="w-6 mr-8" />
          </a>
          {/* <a href="wda">
            <img src={TikTok} alt="" className="w-6" />
          </a> */}
          <a
            href="https://www.facebook.com/HostnGhost/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Face} alt="" className="w-6" />
          </a>
        </div>
      </div>
    );
  }
}

export default Footer;
