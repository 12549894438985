import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import img1 from "../imgs/moto-animation3.gif";
import cloud from "../imgs/Group 195.png";
import cloud1 from "../imgs/Group 211.png";
import bg01 from "../imgs/Group 166.png";
import bg02 from "../imgs/Group 174.png";
import bg03 from "../imgs/Group 181.png";
import img2 from "../imgs/Group 36.png";
import img3 from "../imgs/Group 157.png";
import img4 from "../imgs/Group 159.png";
import gr1 from "../imgs/Group 183.png";
import gr2 from "../imgs/Group 185.png";
import gr3 from "../imgs/Group 191.png";
import gr4 from "../imgs/Group 189.png";
import gr5 from "../imgs/Group 187.png";
import gr6 from "../imgs/Group 197.png";
import gr7 from "../imgs/Group 199.png";
import gr8 from "../imgs/Group 201.png";
import gr9 from "../imgs/Group 203.png";
import gr10 from "../imgs/Group 205.png";
import gr11 from "../imgs/Group 207.png";
import ghost from "../imgs/ghost@4x.png";
import fish from "../imgs/fishing@4x.png";
import { Link } from "react-router-dom";

function Home() {
  return (
    <>
      <Header />
      <section>
        <div className="grid grid-cols-2 mt-10 w-full h-full p-[3rem] sm:px-0 sm:w-full sm:grid-cols-1 md:items-center md:h-[100vw] lg:py-0">
          <div className="grid content-center text-left font-metrischbold sm:text-[30px] md:text-[40px] text-[40px] p-[1rem] lg:py-0">
            <div className="-mt-[5rem] lg:grid lg:items-center lg:mt-0">
              <img
                alt=""
                src={cloud}
                className=" w-80 -ml-16 mb-32 sm:w-36 sm:mb-16 sm:-ml-8 lg:w-52 lg:mb-8 lg:-mt-8"
              />
              <h1 className="text-[75px] font-metrischextrabold -mb-6 sm:text-[32px] sm:mb-auto lg:text-[44px] lg:mb-auto xl:text-[65px] ">
                Helping Restaurants{" "}
              </h1>
              <h2 className="text-[#FB2306] text-[65px] font-metrischextrabold sm:text-[28px] lg:text-[38px] xl:text-[55px]">
                Expand More Efficiently
                <br />
              </h2>
              <h2 className="text-[35px] sm:text-[15px] sm:-mt-0 lg:text-[20px] xl:text-[29px]">
                by matching the brand with a potential host
              </h2>
            </div>
          </div>
          <div className="overflow-hidden sm:flex sm:justify-center">
            <img
              src={img1}
              alt="Loading..."
              className="ml-[5rem] sm:ml-0 sm:mt-8 lg:w-full lg:grid lg:items-center lg:ml-0"
            />
          </div>
        </div>
      </section>
      <section className="w-full p-16 overflow-x-hidden mt-20 sm:-mt-0 lg:mt-0 ">
        <div className="grid grid-cols-2 gap-8 w-full sm:grid-cols-1 sm:gap-0">
          <div className="grid content-center items-center justify-start text-left">
            <img
              alt=""
              src={bg01}
              className="-mb-[5rem] ml-[20rem] w-[12rem] sm:w-28 sm:-mb-12 sm:ml-[10rem] md:ml-[15rem] md:w-[10rem] lg:w-32 lg:ml-60 lg:-mb-16"
            />
            <h3 className="text-[#FB2306] font-metrischextrabold text-[65px] md:text-[45px] sm:text-[35px] lg:text-[44px]">
              What We Do?
            </h3>
            <p className="sm:hidden lg:hidden font-metrisch text-[35px] md:text-[30px] sm:text-[20px] sm:text-center">
              We help restaurants expand
              <br />
              more efficiently
            </p>
            <p className="hidden sm:grid font-metrisch text-[35px] md:text-[30px] sm:text-[20px] sm:text-center ">
              We help restaurants expand more efficiently
            </p>
            <p className="hidden lg:grid font-metrisch text-[30px] md:text-[30px] sm:text-[20px] sm:text-center">
              We help restaurants
              <br />
              expand more efficiently
            </p>
          </div>
          <div>
            <img
              alt=""
              src={cloud}
              className="ml-[37rem] mb-12 w-80 sm:ml-[10rem] sm:mt-8 sm:w-36 lg:ml-[18rem] lg:w-52"
            />
            <img alt="" src={img2} className=" w-[45rem] sm:w-auto sm:h-auto" />
          </div>
        </div>
      </section>
      <section className=" p-16 overflow-hidden sm:hidden">
        <div className="grid grid-cols-2 gap-32 items-center lg:gap-x-8">
          <div className="lg:flex lg:items-center">
            <img alt="" src={img3} className=" w-[45rem]" />
          </div>
          <div className="grid content-center items-center justify-start text-left pl-8">
            <img
              alt=""
              src={bg02}
              className="-mb-[5rem] ml-[25rem] w-[12rem] lg:w-32 lg:-mb-14 lg:ml-[20rem]"
            />
            <h3 className="text-[#FB2306] font-metrischextrabold text-[65px] md:text-[50px] sm:text-[35px] lg:text-[44px]">
              How Do We Do It?
            </h3>
            <p className="font-metrisch text-[35px] md:text-[30px] sm:text-[20px] lg:text-[30px]">
              We match the brand with a potential{" "}
              <br className="lg:hidden xl:hidden" />
              host to kick start in a different location
              <br className="xl:hidden" />
              and we onboard their <br className="hidden lg:inline" />
              menu to start selling
            </p>
          </div>
        </div>
      </section>
      {/* Mobile */}
      <section className="px-16 overflow-hidden hidden sm:grid">
        <div className="grid grid-cols-2 items-center sm:grid-cols-1">
          <div className="grid content-center items-center justify-start text-left">
            <img alt="" src={bg02} className="-mb-[2.5rem] w-28 -ml-4" />
            <h3 className="text-[#FB2306] font-metrischextrabold text-[35px] text-center">
              How Do We Do It?
            </h3>
            <p className="font-metrisch text-[35px] md:text-[30px] sm:text-[20px] text-center">
              We match the brand with a potential
              <br />
              host to kick start in a different location
              <br />
              and we onboard their menu to start selling
            </p>
          </div>
          <div className="md:flex md:items-center">
            <img alt="" src={img3} className=" w-[45rem] mt-16 " />
          </div>
        </div>
      </section>
      <section className="w-full p-16 overflow-x-hidden lg:py-0">
        <div className="grid grid-cols-2 gap-8 w-full sm:grid-cols-1">
          <div className="grid content-center items-center justify-start text-left">
            <img
              alt=""
              src={bg03}
              className="-mb-[5rem] ml-[25rem] w-[12rem] md:w-52 md:-mb-20 md:ml-[12rem] sm:w-28 sm:-mb-12 sm:ml-[10rem] lg:w-32 lg:-mb-12 lg:ml-[20rem]"
            />
            <h3 className="text-[#FB2306] font-metrischextrabold text-[65px] sm:text-[35px] sm:text-center lg:text-[44px]">
              Why Do We Do It?
            </h3>
            <p className="font-metrisch text-[35px] sm:text-[20px] sm:text-center lg:text-[30px]">
              We believe that creating & expanding
              <br className="lg:hidden" />
              restaurants <br className="hidden lg:inline" />
              can be done easier
            </p>
          </div>
          <div className="grid justify-items-end">
            <img
              alt=""
              src={cloud}
              className="-mr-16 w-80 mb-32 sm:w-36 sm:mb-16 sm:-ml-8 lg:w-52"
            />
            <img alt="" src={img4} className="w-[45rem]" />
          </div>
        </div>
      </section>
      <section className="w-full overflow-x-hidden lg:pt-32">
        <img
          alt=""
          src={cloud}
          className=" w-80 sm:w-36 sm:mb-16 sm:-ml-8 lg:w-52"
        />
        <h3 className="text-[#FB2306] font-metrischextrabold text-[65px] mt-[4rem] sm:text-[30px] sm:mt-32 lg:text-[44px]">
          Who are the Hosts?
        </h3>
        <div className="justify-center grid py-16 sm:py-8">
          <div className="grid grid-cols-5 w-full gap-8 justify-center sm:grid-cols-1 sm:justify-items-center sm:gap-8 lg:gap-x-0">
            <div className="grid justify-items-center">
              <img
                alt=""
                src={gr1}
                className="w-[70%] sm:w-1/2 sm:-mb-4 lg:w-1/2"
              />
              <p className="font-metrischbold text-[25px] text-center mt-8 sm:text-[20px] lg:text-[17px] lg:items-start lg:flex">
                Restaurants & Cafes
              </p>
            </div>
            <div className="grid justify-items-center">
              <img
                alt=""
                src={gr2}
                className="w-[70%] sm:w-1/2 sm:-mb-4 lg:w-1/2"
              />
              <p className="font-metrischbold text-[25px] text-center mt-8 sm:text-[20px] lg:text-[17px] lg:items-start lg:flex">
                Hotel Kitchens
              </p>
            </div>
            <div className="justify-items-center grid">
              <img
                alt=""
                src={gr3}
                className="w-[70%] sm:w-1/2 sm:-mb-4 lg:w-1/2"
              />
              <p className="font-metrischbold text-[25px] text-center mt-8 sm:text-[20px] lg:text-[17px] lg:items-start lg:flex">
                Central Kitchens
              </p>
            </div>
            <div className="grid justify-items-center">
              <img
                alt=""
                src={gr4}
                className="w-[70%] sm:w-1/2 sm:-mb-4 lg:w-1/2"
              />
              <p className="font-metrischbold text-[25px] text-center mt-8 sm:text-[20px] lg:text-[17px] lg:items-start lg:flex">
                Cloud Kitchen Providers
              </p>
            </div>
            <div className="grid justify-items-center">
              <img
                alt=""
                src={gr5}
                className="w-[70%] sm:w-1/2 sm:-mb-4 lg:w-1/2"
              />
              <p className="font-metrischbold text-[25px] text-center mt-8 sm:text-[20px] lg:text-[17px] lg:items-start lg:flex">
                Special Type of Menus
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="w-full overflow-x-hidden">
        <div className="flex justify-end">
          <img
            alt=""
            src={cloud}
            className=" w-80 mt-20 sm:w-36 sm:my-4  sm:-ml-8 lg:w-52"
          />
        </div>
        <h3 className="text-[#FB2306] font-metrischextrabold text-[65px] mt-[4rem] sm:text-[30px] lg:text-[44px]">
          Who are the Ghosts?
        </h3>
        <div className="justify-center justify-items-center grid py-16 sm:py-8">
          <div className="grid grid-cols-6 w-[90%] gap-10 justify-center md:hidden sm:grid-cols-1 sm:justify-items-center sm:gap-8 ">
            <div className="grid justify-items-center">
              <img alt="" src={gr6} className="sm:w-1/2 sm:-mb-4" />
              <p className="font-metrischbold text-[25px] text-center mt-8 sm:text-[20px] lg:text-[17px]">
                Expanding Your Brand
              </p>
            </div>
            <div className="grid justify-items-center">
              <img alt="" src={gr7} className="sm:w-1/2 sm:-mb-4" />
              <p className="font-metrischbold text-[25px] text-center mt-8 sm:text-[20px] lg:text-[17px]">
                Inovations With
                <br />
                Little Capital
              </p>
            </div>
            <div className="grid justify-items-center">
              <img alt="" src={gr8} className="sm:w-1/2 sm:-mb-4" />
              <p className="font-metrischbold text-[25px] text-center mt-8 sm:text-[20px] lg:text-[17px]">
                Existing Cloud Brands
              </p>
            </div>
            <div className="grid justify-items-center content-start">
              <img alt="" src={gr9} className="sm:w-1/2 sm:-mb-4" />
              <p className="font-metrischbold text-[25px] text-center mt-8 sm:text-[20px] lg:text-[17px]">
                Test New Markets Around You
              </p>
            </div>
            <div className="grid justify-items-center content-start">
              <img alt="" src={gr10} className="sm:w-1/2 sm:-mb-4" />
              <p className="font-metrischbold text-[25px] text-center mt-8 sm:text-[20px] lg:text-[17px]">
                Home
                <br />
                Businesses
              </p>
            </div>
            <div className="grid justify-items-center">
              <img alt="" src={gr11} className="sm:w-1/2 sm:-mb-4" />
              <p className="font-metrischbold text-[25px] text-center mt-8 sm:text-[20px] lg:text-[17px]">
                Influencers & Celebrities
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="w-full py-16 pr-16 pl-32 overflow-x-hidden mb-16 sm:p-0 lg:px-16 lg:pt-0">
        <div className="grid grid-cols-2 sm:grid-cols-1">
          <div className="grid text-left justify-start items-center">
            <img
              alt=""
              src={cloud1}
              className="-ml-[10rem] mb-16 w-80 mt-[12rem] sm:mt-8"
            />
            <h3 className="text-[#FB2306] font-metrischextrabold text-[65px] -ml-5 sm:text-[35px] sm:px-4 sm:m-0 lg:text-[44px]">
              Ghosts Benefits
            </h3>
            <div className="sm:px-8">
              <ul className="list-disc">
                <li className="font-metrisch text-[25px] sm:text-[20px] text-black">
                  Requirements and standards are already in practice and
                  monitored by local authorities
                </li>
                <li className="font-metrisch text-[25px] sm:text-[20px] text-black">
                  Partner with a local host that knows the market
                </li>
                <li className="font-metrisch text-[25px] sm:text-[20px] text-black">
                  Matching the same category of brands gives a taste of the
                  market before expanding to it
                </li>
                <li className="font-metrisch text-[25px] sm:text-[20px] text-black">
                  Introduction to the cloud restaurant concept with less risk
                </li>
                <li className="font-metrisch text-[25px] sm:text-[20px] text-black">
                  1/1 matching a ghost with a host
                </li>
              </ul>
            </div>
            <div className="-ml-6 mt-6 sm:m-0 sm:px-4 sm:py-4">
              <Link
                className=" hover:text-white font-metrischbold flex justify-start "
                to={"/register-ghost"}
              >
                <button className=" p-2 text-[20px] border-[3px] rounded-[6px] border-[#FB2306] text-[#FB2306] hover:bg-[#FB2306] hover:text-white">
                  Become A Ghost
                </button>
              </Link>
            </div>
          </div>
          <div className="grid justify-center pt-[20rem] md:items-center sm:pt-0 ">
            <img
              alt=""
              src={ghost}
              className="w-[25rem] sm:w-1/2 sm:flex sm:justify-self-center sm:mt-8 lg:flex lg:justify-self-end lg:w-2/3 lg:mt-[8rem] "
            />
          </div>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-1 sm:hidden">
          <div className="grid justify-start pt-[20rem] items-center">
            <img alt="" src={fish} className="w-[25rem] lg:-ml-8" />
          </div>
          <div className="grid text-left justify-start items-center">
            <img
              alt=""
              src={cloud1}
              className="ml-[40rem] w-80 lg:ml-[13rem]"
            />
            <h3 className="text-[#FB2306] font-metrischextrabold text-[65px] -ml-6 -mb-[9rem] lg:text-[44px] lg:-mb-[4rem]">
              Hosts Benefits
            </h3>
            <ul className="list-disc">
              <li className="font-metrisch text-[25px]">
                Better utilize the equipment and staff
                <br className="inline-block" /> to increase capacity
              </li>
              <li className="font-metrisch text-[25px]">
                Increase orders being prepared <br className="inline-block" />
                and get extra margins
              </li>
              <li className="font-metrisch text-[25px]">
                Increase ROI on the capital and
                <br className="inline-block" /> other costs
              </li>
            </ul>
            <div className="-mt-[8rem] -ml-6 lg:-mt-[2rem]">
              <Link
                className=" hover:text-white font-metrischbold flex justify-start "
                to={"/register-host"}
              >
                <button className=" p-2 text-[20px] border-[3px] rounded-[6px] border-[#FB2306] text-[#FB2306] hover:bg-[#FB2306] hover:text-white">
                  Become A Host
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="grid md:hidden lg:hidden xl:hidden 2xl:hidden grid-cols-2 sm:grid-cols-1 ">
          <div className="grid text-left justify-start items-center">
            <img alt="" src={cloud1} className="w-1/2 -ml-12" />
            <h3 className="text-[#FB2306] font-metrischextrabold text-[35px] px-4 mt-8">
              Hosts Benefits
            </h3>
            <div className="px-8">
              <ul className="list-disc">
                <li className="font-metrisch text-[20px]">
                  Better utilize the equipment and staff
                  <br className="inline-block" /> to increase capacity
                </li>
                <li className="font-metrisch text-[20px]">
                  Increase orders being prepared <br className="inline-block" />
                  and get extra margins
                </li>
                <li className="font-metrisch text-[20px]">
                  Increase ROI on the capital and
                  <br className="inline-block" /> other costs
                </li>
              </ul>
            </div>
            <div className="px-4 py-8">
              <Link
                className=" hover:text-white font-metrischbold flex justify-start "
                to={"/register-host"}
              >
                <button className=" p-2 text-[20px] border-[3px] rounded-[6px] border-[#FB2306] text-[#FB2306] hover:bg-[#FB2306] hover:text-white">
                  Become A Host
                </button>
              </Link>
            </div>
          </div>
          <div className="grid justify-start px-8 items-center">
            <img alt="" src={fish} className="w-2/3 flex justify-self-center" />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Home;
