import "./App.css";
import GhostForm from "./components/GhostForm";
import HostForm from "./components/HostForm";
import Home from "./components/Home";
import { Route, Routes } from "react-router-dom";
import Page404 from "./components/Page404";
import HowItWorks from "./components/HowItWorks";
import AboutUs from "./components/AboutUs";
import Favicon from "react-favicon";
import cloud from "./imgs/ghost.jpg";

function App() {
  return (
    <div className="App">
      <Favicon url={cloud}></Favicon>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register-ghost" element={<GhostForm />} />
        <Route path="/register-host" element={<HostForm />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </div>
  );
}

export default App;
