import React from "react";
import Header from "./Header";
import icecream from "../imgs/Group 62.png";
import Person1 from "../imgs/Group 59.png";
import Person2 from "../imgs/Group 75.png";
import Nuddles from "../imgs/Group 64.png";
import Leb from "../imgs/leb.png";
import Kuw from "../imgs/kuw.png";
// import UAE from '../imgs/uae.png';
import Join from "../imgs/ghost host cloud@4x.png";
import Footer from "./Footer";
import lnkdin from "../imgs/Icon awesome-linkedin.png";
import Abed from "../imgs/team/abed.png";
import Fadi from "../imgs/team/fadi.png";
import Joe from "../imgs/team/joe.png";
import Mayssa from "../imgs/team/mayssa.png";
import Tarek from "../imgs/team/tarek.png";
import Thalia from "../imgs/team/thalia.png";
import Vestale from "../imgs/team/vestale.png";
import Jaafar from "../imgs/team/jaafar2.png";

function AboutUs() {
	return (
		<div className="overflow-hidden">
			<Header />
			<section className="h-[100vh] sm:h-full lg:h-full px-[3.5rem] flex items-center lg:mt-8">
				<div className="grid grid-cols-2 w-full sm:grid-cols-1 sm:pt[10rem]">
					<div className="justify-start text-left">
						<h2 className="text-[#FB2306] font-metrischextrabold text-[60px] sm:text-[40px] lg:text-[40px]">
							Our Mission
						</h2>
						<p className="font-metrisch text-[30px] sm:text-[20px] lg:text-[20px]">
							To create an easier process through
							<br /> our platform for restaurants &<br />
							concepts to be born or grow in
							<br />
							different locations around the world.
						</p>
						<img
							src={icecream}
							alt=""
							className="sm:w-[10rem] lg:w-[10rem]"
						/>
					</div>
					<div className="flex justify-center items-center sm:w-[100%]">
						<img
							src={Person1}
							alt=""
						/>
					</div>
				</div>
			</section>
			<section className="sm:h-full lg:h-full h-[100vh] px-[3.5rem] flex items-center lg:py-8">
				<div className="grid grid-cols-2 w-full sm:grid-cols-1">
					<div className="flex justify-items-end items-center sm:hidden">
						<img
							src={Person2}
							alt=""
						/>
					</div>
					<div className="justify-center grid text-left">
						<div className="flex justify-end -mt-[10rem] -mb-[10rem] ml-[20rem] w-[20rem] h-[20rem] lg:-mb-20 lg:mt-10 lg:ml-[15rem] lg:w-[10rem] lg:h-[10rem] sm:-mb-20 sm:mt-10 sm:ml-44 sm:w-[10rem] sm:h-[10rem] ">
							<img
								src={Nuddles}
								alt=""
							/>
						</div>
						<div className="lg:ml-12">
							<h2 className="text-[#FB2306] font-metrischextrabold text-[60px] sm:text-[40px] lg:text-[40px]">
								Our Vision
							</h2>
							<p className="font-metrisch text-[30px] sm:text-[20px] lg:text-[20px] sm:mb-10">
								To cloudify any new concept or restaurant <br />
								willing to expand and scale with ease anywhere <br />
								in the world using our platform.
							</p>
						</div>
					</div>
				</div>
			</section>
			<section className="h-[50vh] sm:h-full px-[3.5rem] bg-[#FB2306] bg-opacity-10 grid items-center justify-center">
				<div>
					<h2 className="text-[#FB2306] font-metrischextrabold text-[60px] sm:text-[40px] lg:text-[40px] mt-8">
						Our Locations
					</h2>
				</div>
				<div className="grid grid-cols-2 sm:grid-cols-1 gap-60 sm:gap-10 my-5 items-end justify-center ">
					<div className="grid justify-center">
						<img
							src={Leb}
							alt=""
							className="flex justify-self-center"
						/>
						<p className="font-metrischbold text-[30px] sm:text-[15px] mt-8">Lebanon</p>
					</div>
					<div className="grid justify-center">
						<img
							src={Kuw}
							alt=""
							className="flex justify-self-center"
						/>
						<p className="font-metrischbold text-[30px] sm:text-[15px] mt-8">Kuwait</p>
					</div>
					{/* <div className="grid justify-center">
            <img src={UAE} alt="" className="flex justify-self-center" />
            <p className="font-metrischbold text-[30px] sm:text-[15px] mt-8">
              UAE
            </p>
          </div> */}
				</div>
			</section>
			<section className="grid justify-center">
				<div>
					<h2 className="text-[#FB2306] font-metrischextrabold text-[60px] sm:text-[40px] lg:text-[40px] mt-[11rem] mb-0  sm:mb-0">
						Meet The Team
					</h2>
				</div>
				<div className="grid grid-cols-3 gap-52 sm:justify-items-center w-full my-[5rem] sm:grid-cols-1 sm:gap-44 lg:gap-0">
					<div className="border-[#FB2306] w-60 h-60 p-4 shadow-foot1 rounded-lg">
						<img
							src={Abed}
							alt=""
						/>
						<p className="font-metrischbold text-[#FB2306] text-[20px] mt-8">Abed Al Kadiri</p>
						<p className="font-metrischbold opacity-50 text-[15px]">Founder & CEO</p>
						<a
							href="https://linkedin.com/in/abdelrahman-elkaderi"
							className="flex justify-center mt-4"
							target="_blank"
							rel="noopener noreferrer">
							<img
								alt=""
								src={lnkdin}
							/>
						</a>
					</div>
					<div className="border-[#FB2306] w-60 h-60 p-4 shadow-foot1 rounded-lg">
						<img
							src={Tarek}
							alt=""
						/>
						<p className="font-metrischbold text-[#FB2306] text-[20px] mt-8">Tarek Al Mohammad</p>
						<p className="font-metrischbold opacity-50 text-[15px]">CFO</p>
						<a
							href="https://linkedin.com/in/tarek-mohamad-849398aa"
							className="flex justify-center mt-4"
							target="_blank"
							rel="noopener noreferrer">
							<img
								alt=""
								src={lnkdin}
							/>
						</a>
					</div>
					<div className="border-[#FB2306] w-60 h-60 p-4 shadow-foot1 rounded-lg sm:justify-center sm:grid">
						<img
							src={Vestale}
							alt=""
						/>
						<p className="font-metrischbold text-[#FB2306] text-[20px] mt-8">Vestale Saliba</p>
						<p className="font-metrischbold opacity-50 text-[15px]">CMO</p>
						<a
							href="https://linkedin.com/in/vestale-saliba"
							className="flex justify-center mt-4"
							target="_blank"
							rel="noopener noreferrer">
							<img
								alt=""
								src={lnkdin}
							/>
						</a>
					</div>
				</div>
				<div className="grid grid-cols-3 gap-52 w-full my-[5rem] sm:grid-cols-1 sm:gap-44 sm:justify-items-center lg:gap-4">
					<div className="border-[#FB2306] w-60 h-60 p-4 shadow-foot1 rounded-lg">
						<img
							src={Jaafar}
							alt=""
						/>
						<p className="font-metrischbold text-[#FB2306] text-[20px] mt-8">Jaafar Serhan</p>
						<p className="font-metrischbold opacity-50 text-[15px]">CTO</p>
						<a
							href="https://www.linkedin.com/in/jaafar-serhan-87497087"
							className="flex justify-center mt-4"
							target="_blank"
							rel="noopener noreferrer">
							<img
								alt=""
								src={lnkdin}
							/>
						</a>
					</div>
					<div className="border-[#FB2306] w-60 h-60 p-4 shadow-foot1 rounded-lg">
						<img
							src={Fadi}
							alt=""
						/>
						<p className="font-metrischbold text-[#FB2306] text-[20px] mt-8">Fadi Ajami</p>
						<p className="font-metrischbold opacity-50 text-[15px]">Senior Frontend Developer</p>
						<a
							href="https://linkedin.com/in/fadi-ajami-615a1b1b8"
							className="flex justify-center mt-4"
							target="_blank"
							rel="noopener noreferrer">
							<img
								alt=""
								src={lnkdin}
							/>
						</a>
					</div>
					<div className="border-[#FB2306] w-60 h-60 p-4 shadow-foot1 rounded-lg sm:justify-center sm:grid">
						<img
							src={Mayssa}
							alt=""
						/>
						<p className="font-metrischbold text-[#FB2306] text-[20px] mt-8">Mayssa Kaady</p>
						<p className="font-metrischbold opacity-50 text-[15px]">UX-UI Specialist</p>
						<a
							href="https://www.linkedin.com/in/mayssa-kaady-647556131"
							className="flex justify-center mt-4"
							target="_blank"
							rel="noopener noreferrer">
							<img
								alt=""
								src={lnkdin}
							/>
						</a>
					</div>
				</div>
				<div className="grid grid-cols-3 gap-52 w-full my-[5rem] sm:grid-cols-1 sm:gap-44 sm:justify-items-center lg:gap-4">
					<div className="border-[#FB2306] w-60 h-60 p-4 shadow-foot1 rounded-lg">
						<img
							src={Joe}
							alt=""
						/>
						<p className="font-metrischbold text-[#FB2306] text-[20px] mt-8">Joe Stephan</p>
						<p className="font-metrischbold opacity-50 text-[15px]">Operations</p>
						<a
							href="https://linkedin.com/in/joe-stephan-73369221a"
							className="flex justify-center mt-4"
							target="_blank"
							rel="noopener noreferrer">
							<img
								alt=""
								src={lnkdin}
							/>
						</a>
					</div>
					<div className="border-[#FB2306] w-60 h-60 p-4 shadow-foot1 rounded-lg">
						<img
							src={Thalia}
							alt=""
						/>
						<p className="font-metrischbold text-[#FB2306] text-[20px] mt-8">Thalia Harouny</p>
						<p className="font-metrischbold opacity-50 text-[15px]">UX-UI Specialist</p>
						<a
							href="https://linkedin.com/in/thalia-el-harouny-8b7a5719a"
							className="flex justify-center mt-4"
							target="_blank"
							rel="noopener noreferrer">
							<img
								alt=""
								src={lnkdin}
							/>
						</a>
					</div>
					<div className="sm:hidden"></div>
				</div>
			</section>
			<section className="px-[3.5rem] grid items-start justify-center">
				<h2 className="text-[#FB2306] font-metrischextrabold text-[60px] lg:text-[40px] sm:text-[40px] mt-[7rem] mb-[3rem]">
					Ready To Join The Team?
				</h2>
				<div className="flex justify-center mb-[2rem]">
					<img
						src={Join}
						alt=""
						className="sm:w-[20rem] w-[50rem] xl:w-[30rem]"
					/>
				</div>
				<p className="font-metrisch text-[25px] sm:text-[15px]">
					Whether you're a foodie, in the food industry, or just passionate about food.
					<br />
					Be part of the #1 cloud kitchen company & send your resume to the email below
				</p>
				<h2 className="text-[#FB2306] font-metrischextrabold text-[40px] lg:text-[40px] sm:text-[20px] mt-[1rem]">
					hello@hostnghost.com
				</h2>
				<a href="mailto:hello@hostnghost.com">
					<button className=" p-2 text-[20px] mb-[5rem] mt-4 font-metrischbold border-[3px] rounded-[6px] border-[#FB2306] text-[#FB2306] hover:bg-[#FB2306] hover:text-white">
						Join Now
					</button>
				</a>
			</section>
			<Footer />
		</div>
	);
}

export default AboutUs;
