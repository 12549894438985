import React from "react";
import logo from "../imgs/Group 2.png";
import { Link } from "react-router-dom";
import Navbar from "../components/navbar";

function Header() {
  return (
    <>
      <nav className="grid grid-cols-8 content-center shadow-[0_15px_30px_-20px_rgba(251,35,6,0.3)] sm:hidden lg:hidden">
        <div className="col-span-1">
          <Link to="/">
            <img src={logo} alt="Host & Ghost" className="w-20 ml-10 sm:w-14" />
          </Link>
        </div>
        <div className="col-span-3"></div>
        <div className="col-span-4 grid grid-cols-4 items-center">
          <div className="col-span-3 grid grid-cols-5">
            <div></div>
            <div></div>
            <div className="flex justify-center">
              <Link
                className="font-metrischbold hover:text-[#FB2306]"
                to="/how-it-works"
              >
                How It Works
              </Link>
            </div>
            <div className="flex justify-center items-center">
              <Link
                className="font-metrischbold hover:text-[#FB2306]"
                to="/about-us"
              >
                About Us
              </Link>
            </div>
            <div className="flex justify-center items-center">
              <Link
                className=" font-metrischbold hover:text-[#FB2306]"
                to={"/register-host"}
              >
                For Hosts
              </Link>
            </div>
          </div>
          <div className="flex">
            <Link
              className=" hover:text-white font-metrischbold flex justify-end "
              to={"/register-ghost"}
            >
              <button className=" py-1 px-3 xl:px-1 border-[3px] rounded-[6px] border-[#FB2306] text-[#FB2306] hover:bg-[#FB2306] hover:text-white">
                Become A Ghost
              </button>
            </Link>
          </div>
        </div>
      </nav>
      <div className="xl:hidden 2xl:hidden">
        <Navbar />
      </div>
    </>
  );
}

export default Header;
