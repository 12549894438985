// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDXLk2dvXsHM4I7G09hwqPnSKytUnoDjPk",
  authDomain: "hostnghoststorage.firebaseapp.com",
  projectId: "hostnghoststorage",
  storageBucket: "hostnghoststorage.appspot.com",
  messagingSenderId: "842269608596",
  appId: "1:842269608596:web:ba15ca0c9b5718c05bc281",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);
