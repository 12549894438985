import React from "react";
import { Link } from "react-router-dom";
import img404 from "../imgs/Group 34.png";
import Header from "./Header";

function Page404() {
  return (
    <>
      <Header />
      <div className="pt-14 grid grid-rows-1 grid-cols-2 items-center">
        <div className="mx-auto">
          <h1 className="font-metrischextrabold leading-[72px] text-[60px] text-left">
            Oops! Looks Like
            <br />
            Something Went Wrong
          </h1>
          <h3 className=" text-[#FB2306] font-metrisch pt-4 text-[20px] text-left">
            Go Back To The{" "}
            <Link className="underline" to={"/"}>
              Homepage
            </Link>
          </h3>
        </div>
        <div className="mx-auto">
          <img src={img404} alt="404" />
        </div>
      </div>
    </>
  );
}

export default Page404;
