import React, { Component } from "react";
import Header from "./Header";
import dude from "../imgs/Group 21.png";
import Arrow from "../imgs/Icon awesome-arrow-right.png";

export class HostForm extends Component {
  constructor(props) {
    super(props);

    this.businessNameRef = React.createRef();
    this.nameRef = React.createRef();
    this.phoneRef = React.createRef();
    this.emailRef = React.createRef();
    this.countryRef = React.createRef();

    this.state = {
      businessName: "",
      fullName: "",
      phoneNumber: null,
      email: "",
      countryOfResidance: "",
    };
  }
  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
    // console.log([input]);
  };

  stepValidation = () => {
    let isValid = true;
    if (this.state.businessName === "") {
      this.businessNameRef.current.className =
        "p-3 bg-[#FB2306] animate-[bounce_0.7s_ease-in-out_2] md:mr-[10rem] text-white font-metrisch border-2 border-black hover:border-[#FB2306] mr-60 sm:mx-4 focus:animate-none focus:border-[#FB2306] outline-none rounded-[6px] mt-6";
      isValid = false;
    } else {
      this.businessNameRef.current.className =
        "p-3 font-metrisch border-2 border-black hover:border-[#FB2306] mr-60 sm:mx-4 md:mr-[5rem] focus:border-[#FB2306] outline-none rounded-[6px] mt-6";
    }
    if (this.state.fullName === "") {
      this.nameRef.current.className =
        "p-3 bg-[#FB2306] animate-[bounce_0.7s_ease-in-out_2] md:mr-[10rem] text-white font-metrisch border-2 border-black hover:border-[#FB2306] mr-60 sm:mx-4 focus:animate-none focus:border-[#FB2306] outline-none rounded-[6px] mt-6";
      isValid = false;
    } else {
      this.nameRef.current.className =
        "p-3 font-metrisch border-2 border-black hover:border-[#FB2306] mr-60 sm:mx-4 md:mr-[5rem] focus:border-[#FB2306] outline-none rounded-[6px] mt-6";
    }
    if (this.state.phoneNumber === null) {
      this.phoneRef.current.className =
        "p-3 bg-[#FB2306] mt-4 animate-[bounce_0.7s_ease-in-out_2] md:mr-[10rem] text-white font-metrisch border-2 border-black hover:border-[#FB2306] mr-60 sm:mx-4 focus:animate-none focus:border-[#FB2306] outline-none rounded-[6px] mb-1";
      isValid = false;
    } else {
      this.phoneRef.current.className =
        "p-3 font-metrisch border-2 border-black hover:border-[#FB2306] mr-60 sm:mx-4 md:mr-[5rem] focus:border-[#FB2306] outline-none rounded-[6px] mt-6";
    }
    if (this.state.email === "") {
      this.emailRef.current.className =
        "p-3 bg-[#FB2306] animate-[bounce_0.7s_ease-in-out_2] md:mr-[10rem] text-white font-metrisch border-2 border-black hover:border-[#FB2306] mr-60 sm:mx-4 focus:animate-none focus:border-[#FB2306] mt-4 outline-none rounded-[6px] mb-1";
      isValid = false;
    } else {
      this.emailRef.current.className =
        "p-3 font-metrisch border-2 border-black hover:border-[#FB2306] mr-60 sm:mx-4 md:mr-[5rem] focus:border-[#FB2306] outline-none rounded-[6px] mt-6";
    }
    if (this.state.countryOfResidance === "") {
      this.countryRef.current.className =
        "p-3 bg-[#FB2306] animate-[bounce_0.7s_ease-in-out_2] md:mr-[10rem] text-white font-metrisch border-2 border-black hover:border-[#FB2306] mr-60 sm:mx-4 focus:animate-none focus:border-[#FB2306] outline-none rounded-[6px] mt-6";
      isValid = false;
    } else {
      this.countryRef.current.className =
        "p-3 font-metrisch border-2 border-black hover:border-[#FB2306] mr-60 sm:mx-4 md:mr-[5rem] focus:border-[#FB2306] outline-none rounded-[6px] mt-6";
    }
    return isValid;
  };

  upload = async (e) => {
    try {
      const response = await fetch(
        "https://v1.nocodeapi.com/fadidev/google_sheets/yEdYhzSPMDLnsjLW?tabId=Host",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify([
            [
              this.state.businessName,
              this.state.fullName,
              this.state.phoneNumber,
              this.state.email,
              this.state.countryOfResidance,
            ],
          ]),
        }
      );
      await response.json();
    } catch (error) {
      console.log(error);
    }
  };
  submit = () => {
    alert("Thank you for your Submission.\nOur Team will contact you soon!");
    this.setState({
      businessName: "",
      fullName: "",
      phoneNumber: "",
      email: "",
      countryOfResidance: "",
    });
    this.businessNameRef.current.value = "";
    this.nameRef.current.value = "";
    this.phoneRef.current.value = "";
    this.emailRef.current.value = "";
    this.countryRef.current.value = "";
    // console.log(this.state);
  };

  done = (e) => {
    e.preventDefault();
    let isValid = this.stepValidation();
    if (isValid) {
      this.upload(e);
      this.submit();
    }
  };

  handleKeyPress = (e) => {
    var key = e.key;
    var regex = /^[0-9\b]+$/;
    if (regex.test(key) | (key === "Backspace")) {
      // console.log("You pressed a key: " + key);
    } else {
      e.preventDefault();
    }
  };

  render() {
    return (
      <>
        <Header />
        <div className="grid grid-cols-2 w-full sm:grid-cols-1">
          <div className="grid content-center p-12 sm:p-0">
            <h2 className="flex justify-start md:text-[40px] sm:text-[30px] sm:justify-center text-[50px] font-metrischextrabold -mb-4 sm:-mb-1">
              Become a Host
            </h2>
            <h2 className="flex justify-start md:text-[20px] sm:text-[20px] text-[25px] sm:justify-center text-[#FB2306] font-metrischbold">
              Provide Your Personal Information
            </h2>
            <h2 className="flex justify-start md:text-[15px] sm:text-[14px] text-[15px] opacity-50 sm:justify-center font-metrischbold">
              A representative will be in touch
            </h2>
            <input
              type="text"
              ref={this.businessNameRef}
              className="p-3 font-metrisch border-2 border-black hover:border-[#FB2306] mr-60 sm:mx-4 md:mr-[5rem] focus:border-[#FB2306] outline-none rounded-[6px] mt-6"
              placeholder="Your Business Name"
              defaultValue={this.state.businessName}
              onChange={this.handleChange("businessName")}
            />
            <input
              type="text"
              ref={this.nameRef}
              className="p-3 font-metrisch border-2 border-black hover:border-[#FB2306] mr-60 sm:mx-4 md:mr-[5rem] focus:border-[#FB2306] outline-none rounded-[6px] mt-6"
              placeholder="Your Full Name"
              defaultValue={this.state.fullName}
              onChange={this.handleChange("fullName")}
            />
            <input
              type="tel"
              ref={this.phoneRef}
              className="p-3 font-metrisch border-2 border-black hover:border-[#FB2306] mr-60 sm:mx-4 md:mr-[5rem] focus:border-[#FB2306] outline-none rounded-[6px] mt-6"
              placeholder="Your Phone Number"
              defaultValue={this.state.phoneNumber}
              onChange={this.handleChange("phoneNumber")}
              onKeyDown={(e) => {
                this.handleKeyPress(e);
              }}
            />
            <input
              type="email"
              ref={this.emailRef}
              className="p-3 font-metrisch border-2 border-black hover:border-[#FB2306] mr-60 sm:mx-4 md:mr-[5rem] focus:border-[#FB2306] outline-none rounded-[6px] mt-6"
              placeholder="Your Email"
              defaultValue={this.state.email}
              onChange={this.handleChange("email")}
            />
            <select
              ref={this.countryRef}
              className="p-3 font-metrisch border-2 sm:w-[91%] border-black hover:border-[#FB2306] mr-60 sm:mx-4 focus:border-[#FB2306] outline-none rounded-[6px] mt-6"
              defaultValue={this.state.countryOfResidance}
              onChange={this.handleChange("countryOfResidance")}
            >
              <option value="" disabled>
                Enter Your Country
              </option>
              <option value="AF">Afghanistan</option>
              <option value="AX">Aland Islands</option>
              <option value="AL">Albania</option>
              <option value="DZ">Algeria</option>
              <option value="AS">American Samoa</option>
              <option value="AD">Andorra</option>
              <option value="AO">Angola</option>
              <option value="AI">Anguilla</option>
              <option value="AQ">Antarctica</option>
              <option value="AG">Antigua and Barbuda</option>
              <option value="AR">Argentina</option>
              <option value="AM">Armenia</option>
              <option value="AW">Aruba</option>
              <option value="AU">Australia</option>
              <option value="AT">Austria</option>
              <option value="AZ">Azerbaijan</option>
              <option value="BS">Bahamas</option>
              <option value="BH">Bahrain</option>
              <option value="BD">Bangladesh</option>
              <option value="BB">Barbados</option>
              <option value="BY">Belarus</option>
              <option value="BE">Belgium</option>
              <option value="BZ">Belize</option>
              <option value="BJ">Benin</option>
              <option value="BM">Bermuda</option>
              <option value="BT">Bhutan</option>
              <option value="BO">Bolivia</option>
              <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
              <option value="BA">Bosnia and Herzegovina</option>
              <option value="BW">Botswana</option>
              <option value="BV">Bouvet Island</option>
              <option value="BR">Brazil</option>
              <option value="IO">British Indian Ocean Territory</option>
              <option value="BN">Brunei Darussalam</option>
              <option value="BG">Bulgaria</option>
              <option value="BF">Burkina Faso</option>
              <option value="BI">Burundi</option>
              <option value="KH">Cambodia</option>
              <option value="CM">Cameroon</option>
              <option value="CA">Canada</option>
              <option value="CV">Cape Verde</option>
              <option value="KY">Cayman Islands</option>
              <option value="CF">Central African Republic</option>
              <option value="TD">Chad</option>
              <option value="CL">Chile</option>
              <option value="CN">China</option>
              <option value="CX">Christmas Island</option>
              <option value="CC">Cocos (Keeling) Islands</option>
              <option value="CO">Colombia</option>
              <option value="KM">Comoros</option>
              <option value="CG">Congo</option>
              <option value="CD">
                Congo, Democratic Republic of the Congo
              </option>
              <option value="CK">Cook Islands</option>
              <option value="CR">Costa Rica</option>
              <option value="CI">Cote D'Ivoire</option>
              <option value="HR">Croatia</option>
              <option value="CU">Cuba</option>
              <option value="CW">Curacao</option>
              <option value="CY">Cyprus</option>
              <option value="CZ">Czech Republic</option>
              <option value="DK">Denmark</option>
              <option value="DJ">Djibouti</option>
              <option value="DM">Dominica</option>
              <option value="DO">Dominican Republic</option>
              <option value="EC">Ecuador</option>
              <option value="EG">Egypt</option>
              <option value="SV">El Salvador</option>
              <option value="GQ">Equatorial Guinea</option>
              <option value="ER">Eritrea</option>
              <option value="EE">Estonia</option>
              <option value="ET">Ethiopia</option>
              <option value="FK">Falkland Islands (Malvinas)</option>
              <option value="FO">Faroe Islands</option>
              <option value="FJ">Fiji</option>
              <option value="FI">Finland</option>
              <option value="FR">France</option>
              <option value="GF">French Guiana</option>
              <option value="PF">French Polynesia</option>
              <option value="TF">French Southern Territories</option>
              <option value="GA">Gabon</option>
              <option value="GM">Gambia</option>
              <option value="GE">Georgia</option>
              <option value="DE">Germany</option>
              <option value="GH">Ghana</option>
              <option value="GI">Gibraltar</option>
              <option value="GR">Greece</option>
              <option value="GL">Greenland</option>
              <option value="GD">Grenada</option>
              <option value="GP">Guadeloupe</option>
              <option value="GU">Guam</option>
              <option value="GT">Guatemala</option>
              <option value="GG">Guernsey</option>
              <option value="GN">Guinea</option>
              <option value="GW">Guinea-Bissau</option>
              <option value="GY">Guyana</option>
              <option value="HT">Haiti</option>
              <option value="HM">Heard Island and Mcdonald Islands</option>
              <option value="VA">Holy See (Vatican City State)</option>
              <option value="HN">Honduras</option>
              <option value="HK">Hong Kong</option>
              <option value="HU">Hungary</option>
              <option value="IS">Iceland</option>
              <option value="IN">India</option>
              <option value="ID">Indonesia</option>
              <option value="IR">Iran, Islamic Republic of</option>
              <option value="IQ">Iraq</option>
              <option value="IE">Ireland</option>
              <option value="IM">Isle of Man</option>
              <option value="IT">Italy</option>
              <option value="JM">Jamaica</option>
              <option value="JP">Japan</option>
              <option value="JE">Jersey</option>
              <option value="JO">Jordan</option>
              <option value="KZ">Kazakhstan</option>
              <option value="KE">Kenya</option>
              <option value="KI">Kiribati</option>
              <option value="KP">Korea, Democratic People's Republic of</option>
              <option value="KR">Korea, Republic of</option>
              <option value="XK">Kosovo</option>
              <option value="KW">Kuwait</option>
              <option value="KG">Kyrgyzstan</option>
              <option value="LA">Lao People's Democratic Republic</option>
              <option value="LV">Latvia</option>
              <option value="LB">Lebanon</option>
              <option value="LS">Lesotho</option>
              <option value="LR">Liberia</option>
              <option value="LY">Libyan Arab Jamahiriya</option>
              <option value="LI">Liechtenstein</option>
              <option value="LT">Lithuania</option>
              <option value="LU">Luxembourg</option>
              <option value="MO">Macao</option>
              <option value="MK">
                Macedonia, the Former Yugoslav Republic of
              </option>
              <option value="MG">Madagascar</option>
              <option value="MW">Malawi</option>
              <option value="MY">Malaysia</option>
              <option value="MV">Maldives</option>
              <option value="ML">Mali</option>
              <option value="MT">Malta</option>
              <option value="MH">Marshall Islands</option>
              <option value="MQ">Martinique</option>
              <option value="MR">Mauritania</option>
              <option value="MU">Mauritius</option>
              <option value="YT">Mayotte</option>
              <option value="MX">Mexico</option>
              <option value="FM">Micronesia, Federated States of</option>
              <option value="MD">Moldova, Republic of</option>
              <option value="MC">Monaco</option>
              <option value="MN">Mongolia</option>
              <option value="ME">Montenegro</option>
              <option value="MS">Montserrat</option>
              <option value="MA">Morocco</option>
              <option value="MZ">Mozambique</option>
              <option value="MM">Myanmar</option>
              <option value="NA">Namibia</option>
              <option value="NR">Nauru</option>
              <option value="NP">Nepal</option>
              <option value="NL">Netherlands</option>
              <option value="AN">Netherlands Antilles</option>
              <option value="NC">New Caledonia</option>
              <option value="NZ">New Zealand</option>
              <option value="NI">Nicaragua</option>
              <option value="NE">Niger</option>
              <option value="NG">Nigeria</option>
              <option value="NU">Niue</option>
              <option value="NF">Norfolk Island</option>
              <option value="MP">Northern Mariana Islands</option>
              <option value="NO">Norway</option>
              <option value="OM">Oman</option>
              <option value="PK">Pakistan</option>
              <option value="PW">Palau</option>
              <option value="PS">Palestinian Territory, Occupied</option>
              <option value="PA">Panama</option>
              <option value="PG">Papua New Guinea</option>
              <option value="PY">Paraguay</option>
              <option value="PE">Peru</option>
              <option value="PH">Philippines</option>
              <option value="PN">Pitcairn</option>
              <option value="PL">Poland</option>
              <option value="PT">Portugal</option>
              <option value="PR">Puerto Rico</option>
              <option value="QA">Qatar</option>
              <option value="RE">Reunion</option>
              <option value="RO">Romania</option>
              <option value="RU">Russian Federation</option>
              <option value="RW">Rwanda</option>
              <option value="BL">Saint Barthelemy</option>
              <option value="SH">Saint Helena</option>
              <option value="KN">Saint Kitts and Nevis</option>
              <option value="LC">Saint Lucia</option>
              <option value="MF">Saint Martin</option>
              <option value="PM">Saint Pierre and Miquelon</option>
              <option value="VC">Saint Vincent and the Grenadines</option>
              <option value="WS">Samoa</option>
              <option value="SM">San Marino</option>
              <option value="ST">Sao Tome and Principe</option>
              <option value="SA">Saudi Arabia</option>
              <option value="SN">Senegal</option>
              <option value="RS">Serbia</option>
              <option value="CS">Serbia and Montenegro</option>
              <option value="SC">Seychelles</option>
              <option value="SL">Sierra Leone</option>
              <option value="SG">Singapore</option>
              <option value="SX">Sint Maarten</option>
              <option value="SK">Slovakia</option>
              <option value="SI">Slovenia</option>
              <option value="SB">Solomon Islands</option>
              <option value="SO">Somalia</option>
              <option value="ZA">South Africa</option>
              <option value="GS">
                South Georgia and the South Sandwich Islands
              </option>
              <option value="SS">South Sudan</option>
              <option value="ES">Spain</option>
              <option value="LK">Sri Lanka</option>
              <option value="SD">Sudan</option>
              <option value="SR">Suriname</option>
              <option value="SJ">Svalbard and Jan Mayen</option>
              <option value="SZ">Swaziland</option>
              <option value="SE">Sweden</option>
              <option value="CH">Switzerland</option>
              <option value="SY">Syrian Arab Republic</option>
              <option value="TW">Taiwan, Province of China</option>
              <option value="TJ">Tajikistan</option>
              <option value="TZ">Tanzania, United Republic of</option>
              <option value="TH">Thailand</option>
              <option value="TL">Timor-Leste</option>
              <option value="TG">Togo</option>
              <option value="TK">Tokelau</option>
              <option value="TO">Tonga</option>
              <option value="TT">Trinidad and Tobago</option>
              <option value="TN">Tunisia</option>
              <option value="TR">Turkey</option>
              <option value="TM">Turkmenistan</option>
              <option value="TC">Turks and Caicos Islands</option>
              <option value="TV">Tuvalu</option>
              <option value="UG">Uganda</option>
              <option value="UA">Ukraine</option>
              <option value="AE">United Arab Emirates</option>
              <option value="GB">United Kingdom</option>
              <option value="US">United States</option>
              <option value="UM">United States Minor Outlying Islands</option>
              <option value="UY">Uruguay</option>
              <option value="UZ">Uzbekistan</option>
              <option value="VU">Vanuatu</option>
              <option value="VE">Venezuela</option>
              <option value="VN">Viet Nam</option>
              <option value="VG">Virgin Islands, British</option>
              <option value="VI">Virgin Islands, U.s.</option>
              <option value="WF">Wallis and Futuna</option>
              <option value="EH">Western Sahara</option>
              <option value="YE">Yemen</option>
              <option value="ZM">Zambia</option>
              <option value="ZW">Zimbabwe</option>
            </select>
            <div className="grid grid-rows-1 grid-cols-2 mt-[5rem] md:mt-8 sm:mt-4 sm:px-4">
              <div></div>
              <div className="flex justify-center">
                <button
                  className="flex justify-end content-start font-metrischbold "
                  onClick={this.done}
                >
                  Submit
                  <img
                    className="ml-2    "
                    src={Arrow}
                    width="22px"
                    height="22px"
                    alt=" "
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="grid justify-center content-center py-48 sm:py-4 sm:mx-4">
            <img src={dude} alt="" />
          </div>
        </div>
      </>
    );
  }
}

export default HostForm;
