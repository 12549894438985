import React, { Component, Fragment } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import logo from "../imgs/Group 2.png";
import { Link } from "react-router-dom";
import Img1 from "../imgs/rocket@4x.png";
import Arrow from "../imgs/Icon awesome-arrow-right.png";
import Arrow1 from "../imgs/Icon awesome-arrow-left.png";
import { TbCloudUpload } from "react-icons/tb";
import { storage } from "../firebase";
import { ref, uploadBytes, listAll, getDownloadURL } from "firebase/storage";
import { FormControl, OutlinedInput, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { createTheme, ThemeProvider } from "@mui/material";
import Metrisch from "../fonts/Metrisch-Medium-webfont.woff2";

const theme = createTheme({
  typography: {
    fontFamily: "Metrisch",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: 'Metrisch';
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src: local('Metrisch'), local('Metrisch'), url(${Metrisch}) format('woff2');
        unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
      }
      `,
    },
  },
  breakpoints: {
    values: {
      xxs: 0, // small phone
      xs: 300, // phone
      sm: 600, // tablets
      lg: 900, // desktop
      xl: 1536, // large screens
    },
  },
});

class Step2 extends Component {
  stepValidation = () => {
    const {
      values: { category, files },
    } = this.props;
    let isValid = true;
    if (category.length === 0) {
      this.categoryRef.current.error = true;
      isValid = false;
    }
    if (files === null) {
      this.fileBtnRef.current.className =
        "py-[6rem] bg-[#FB2306] text-white text-[#FB2306] text-[100px] font-metrisch justify-center grid border-2 border-black hover:border-[#FB2306] mr-60 sm:mx-4 focus:border-[#FB2306] outline-none rounded-[6px]";
      isValid = false;
    }
    return isValid;
  };
  continue = (e) => {
    e.preventDefault();
    let isValid = this.stepValidation();
    if (isValid) {
      this.uploadImage();
      this.props.nextStep();
    }
  };
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  btnClk = () => {
    this.fileRef.current.click();
  };
  //
  onChangeFile(event) {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files;
    // console.log(file);
    this.setState({ file }); /// if you want to upload latter
  }

  uploadImage = () => {
    let {
      values: { files, businessName, fileList },
    } = this.props;
    if (files == null) return;
    const fileListRef = ref(storage, `${businessName}/`);
    const fileRef = ref(storage, `${businessName}/${files.name}`);
    uploadBytes(fileRef, files).then(() => {
      // console.log("Image Uploaded");
      listAll(fileListRef).then((response) => {
        response.items.forEach((item) => {
          getDownloadURL(item).then((url) => {
            fileList.push(url);
            // console.log(fileList);
          });
        });
      });
    });
  };

  constructor(props) {
    super(props);
    this.categoryRef = React.createRef();
    this.fileRef = React.createRef();
    this.iconRef = React.createRef();
    this.fileBtnRef = React.createRef();
    // this.labelRef = React.createRef();
    this.state = {
      disabled: false,
      val: "Upload Your Menu here",
    };
  }
  render() {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const { values, handleChange, handleFile } = this.props;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };
    const categs = [
      "Algerian",
      "American",
      "Belgian",
      "Brazilian",
      "British",
      "Cajun",
      "Canadian",
      "Chinese",
      "Cuban",
      "Egyptian",
      "French",
      "German",
      "Greek",
      "Haitian",
      "Hawaiian",
      "Indian",
      "Irish",
      "Italian",
      "Japanese",
      "Jewish",
      "Kenyan",
      "Korean",
      "Latvian",
      "Libyan",
      "Mexican",
      "Mormon",
      "Nigerian",
      "Peruvian",
      "Polish",
      "Portuguese",
      "Russian",
      "Salvadorian",
      "Scottish",
      "Spanish",
      "Swedish",
      "Tahitian",
      "Thai",
      "Tibetan",
      "Turkish",
      "Welsh",
      "Lebanese",
      "MiddleEastern",
    ];
    return (
      <div>
        {/* Header */}
        <div>
          <nav>
            <div className="sm:flex w-full grid grid-rows-1 grid-cols-6 sm:grid-cols-5 content-center items-center">
              <div className="sm:w-full  sm:px-2 w-50 py-4 pl-16">
                <Link to="/">
                  <img src={logo} alt="Host & Ghost" className="w-20 sm:w-14" />
                </Link>
              </div>
              <div className="sm:hidden"></div>
              <div className="sm:hidden flex items-center content-center">
                <span className="bg-[#FB2306] lg:text-[15px] lg:w-[2.5rem] lg:h-[1.5rem] w-7 h-7 rounded-full grid items-center text-white text-[20px] font-metrischbold">
                  1
                </span>
                <span className="grid w-[124pt] lg:text-[15px] text-[20px] font-metrischbold text-left pl-2">
                  About You
                </span>
              </div>
              <div className="flex items-center content-center w-60">
                <span className="bg-[#FB2306] lg:text-[15px] lg:w-6 lg:h-6 sm:w-5 sm:h-5 w-7 h-7 rounded-full grid items-center text-white sm:text-[14px] text-[20px] font-metrischbold">
                  2
                </span>
                <span className="grid w-[124pt] lg:text-[15px] lg:w-[6rem] sm:w-max sm:text-[14px] text-[20px] font-metrischbold text-left sm:pl-1 pl-2 sm:mr-4">
                  About Your Business
                </span>
              </div>
              <div className="sm:hidden flex items-center content-center">
                <span className="bg-[#999999] lg:text-[15px]  lg:w-[2.5rem] lg:h-[1.5rem] w-7 h-7 rounded-full grid items-center text-white text-[20px] font-metrischbold">
                  3
                </span>
                <span className="sm:hidden grid w-[124pt] lg:text-[15px] text-[#999999] text-[20px] font-metrischbold text-left pl-2">
                  Your Goals
                </span>
              </div>
              <div className="sm:hidden flex items-center content-center">
                <span className="bg-[#999999] lg:text-[15px] lg:w-[2.5rem] lg:h-[1.5rem] w-7 h-7 rounded-full grid items-center text-white text-[20px] font-metrischbold">
                  4
                </span>
                <span className="grid w-[124pt] lg:text-[15px] text-[#999999] text-[20px] font-metrischbold text-left pl-2">
                  Your Needs
                </span>
              </div>
            </div>
          </nav>
        </div>
        <Fragment>
          <div className="w-full h-full grid">
            <div className="">
              <ProgressBar
                bgColor="#FB2306"
                baseBgColor="#f7f7f7"
                initCompletedOnAnimation={25}
                // animateOnRender={true}
                completed={37.5}
                isLabelVisible={false}
                height="15px"
              />
            </div>
            <div className="grid sm:grid-cols-1 grid-cols-2 h-full">
              <div className="hidden sm:grid content-center">
                <img className="px-8 mt-8" src={Img1} alt=" " />
              </div>
              <div className="grid content-center sm:py-20 py-10 h-[100vh] lg:px-4 sm:h-full sm:pl-4 sm:pr-4 px-16 -mb-20 shadow-[10px_0_10px_-7px_rgba(251,35,6,0.3)]">
                <h2 className="flex justify-start lg:text-[40px] lg:text-left sm:text-[30px] sm:justify-center text-[40px] font-metrischextrabold -mb-4 sm:-mb-1">
                  About Your Business
                </h2>
                <h2 className="flex justify-start sm:text-[20px] text-[25px] sm:justify-center text-[#FB2306] font-metrischbold">
                  Business Information
                </h2>
                <h2 className="flex justify-start sm:text-[14px] text-[15px] opacity-50 sm:justify-center font-metrischbold">
                  Provide Your Business Information
                </h2>
                <div className="sm:pr-[2rem] pr-60">
                  <ThemeProvider theme={theme}>
                    <FormControl>
                      <Select
                        ref={this.categoryRef}
                        multiple
                        displayEmpty
                        sx={{
                          border: 2,
                          width: {
                            xxs: 260,
                            lg: 450,
                            xl: 590,
                          },
                          justifyContent: "start",
                          justifyItems: "start",
                          alignContent: "start",
                          alignSelf: "start",
                          marginTop: 2,
                        }}
                        value={values.category}
                        onChange={handleChange("category")}
                        input={<OutlinedInput />}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return (
                              <p className="justify-start flex">
                                Enter Your Food Category
                              </p>
                            );
                          }
                          return selected.join(", ");
                        }}
                        MenuProps={MenuProps}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {categs.map((name) => (
                          <MenuItem key={name} value={name}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </ThemeProvider>
                </div>
                <br />

                <input
                  type="file"
                  id="file"
                  ref={this.fileRef}
                  style={{ display: "none" }}
                  onChange={(e) => {
                    handleFile(e);
                    this.onChangeFile.bind(this);
                    this.setState({
                      val: "File Uploaded",
                    });
                    this.fileBtnRef.current.style = "color:white";
                  }}
                />
                <button
                  ref={this.fileBtnRef}
                  onClick={this.btnClk}
                  className="py-[6rem] text-[#FB2306] text-[100px] font-metrisch justify-items-center grid border-2 border-black hover:border-[#FB2306] mr-60 sm:mx-4 focus:border-[#FB2306] outline-none rounded-[6px] "
                >
                  <TbCloudUpload ref={this.iconRef} />
                  <label className="text-[12px] text-black">
                    {this.state.val}
                  </label>
                </button>
                <textarea
                  defaultValue={values.notes}
                  onChange={handleChange("notes")}
                  placeholder="Insert Additional Notes If Needed"
                  rows={5}
                  // className="p-3 font-metrisch border-2 border-black hover:border-[#FB2306] mr-60 focus:border-[#FB2306] outline-none rounded-[6px] mt-6"
                  className="p-3 font-metrisch border-2 border-black hover:border-[#FB2306] mr-60 sm:mx-4 focus:border-[#FB2306] outline-none rounded-[6px] mt-6 "
                />
                <div className="grid grid-rows-1 grid-cols-2 mt-[1.1rem] sm:mt-10 sm:px-4">
                  <div>
                    <button
                      className="flex justify-end content-start text-[#cccccc] font-metrischbold "
                      onClick={this.back}
                    >
                      <img
                        className="mr-2    "
                        src={Arrow1}
                        width="22px"
                        height="22px"
                        alt=" "
                      />
                      Back
                    </button>
                  </div>
                  <div className="flex justify-end lg:justify-start xl:mr-40">
                    <button
                      className="flex justify-end content-start font-metrischbold "
                      onClick={this.continue}
                    >
                      Continue
                      <img
                        className="ml-2    "
                        src={Arrow}
                        width="22px"
                        height="22px"
                        alt=" "
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div className="sm:hidden grid content-center">
                <img className="px-8 mt-[6.1rem]" src={Img1} alt=" " />
              </div>
              <br />
            </div>
          </div>
        </Fragment>
      </div>
    );
  }
}

export default Step2;
