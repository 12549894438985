import React, { Component } from "react";
import Page404 from "../components/Page404";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step2s1 from "./Step2-1";
import Step2s2 from "./Step2-2";
import Step3 from "./Step3";
import Step3s1 from "./Step3-1";
import Step4 from "./Step4";
import Summary from "./Summary";

class GhostForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      name: "",
      email: "",
      phone: null,
      position: "",
      country: "",
      businessName: "",
      businessPhone: null,
      type: "",
      category: [],
      files: null,
      fileList: [],
      notes: "",
      Instagram: "",
      Facebook: "",
      website: "",
      go: [],
      goal: "",
      inter: [],
      interest: "",
      ov: [],
      ovens: "",
      mx: [],
      mixers: "",
      pro: [],
      processors: "",
      slicers: "",
      bladeSize: "",
      microwave: "",
      ice: "",
      grl: [],
      Grills: "",
      juc: [],
      Juicers: "",
      a: 0,
      b: 0,
      o1: false,
      openF: false,
      TnC: false,
    };
  }

  // Go to next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  // Go to prev step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };
  //First Step
  handleOpenF = () => {
    this.setState({
      openF: true,
    });
  };

  firstStep = () => {
    alert("Thank you for your Submission.\nOur Team will contact you soon!");
    this.setState({
      step: 1,
    });

    this.handleReset();
    this.handleOpenF();
  };
  // Handle fields change
  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
    // console.log([input]);
  };
  handleFile = (e) => {
    this.setState({ files: e.target.files[0] });
    // console.log([input]);
  };
  handleFl = (val) => (e) => {
    this.setState({ fl: [val] });
    console.log(this.state.fl);
  };

  //Reset State
  handleReset = () => {
    this.setState({
      step: 1,
      name: "",
      email: "",
      phone: null,
      position: "",
      country: "",
      businessName: "",
      businessPhone: null,
      type: "",
      category: "",
      files: null,
      fileList: [],
      notes: "",
      Instagram: "",
      Facebook: "",
      website: "",
      go: [],
      goal: "",
      inter: [],
      interest: "",
      ov: [],
      ovens: "",
      mx: [],
      mixers: "",
      pro: [],
      processors: "",
      slicers: "",
      bladeSize: "",
      microwave: "",
      ice: "",
      grl: [],
      Grills: "",
      juc: [],
      Juicers: "",
      a: 0,
      b: 0,
      TnC: false,
    });
  };

  //Last Step
  handleInputGoal = (input) => (e) => {
    e.preventDefault();
    const checked = e.target.checked;
    if (checked) {
      this.state.go.push(e.target.value);
      // console.log(this.state.go);
    } else {
      const ioo = this.state.go.findIndex((object) => {
        return object === e.target.value;
      });
      this.state.go.splice(ioo, 1);
      // console.log(this.state.go);
    }
    const t = this.state.go.toString();
    this.setState({
      [input]: t,
    });
  };
  handleInputIntrest = (input) => (e) => {
    const checked = e.target.checked;
    if (checked) {
      this.state.inter.push(e.target.value);
      // console.log(this.state.inter);
    } else {
      const ioo = this.state.inter.findIndex((object) => {
        return object === e.target.value;
      });
      this.state.inter.splice(ioo, 1);
      // console.log(this.state.inter);
    }
    const t = this.state.inter.toString();
    this.setState({
      [input]: t,
    });
  };
  handleInputOven = (input) => (e) => {
    const checked = e.target.checked;
    if (checked) {
      this.state.ov.push(e.target.value);
      // console.log(this.state.ov);
    } else {
      const ioo = this.state.ov.findIndex((object) => {
        return object === e.target.value;
      });
      this.state.ov.splice(ioo, 1);
      // console.log(this.state.ov);
    }
    const t = this.state.ov.toString();
    this.setState({
      [input]: t,
    });
  };
  handleInputMixer = (input) => (e) => {
    const checked = e.target.checked;
    if (checked) {
      this.state.mx.push(e.target.value);
      // console.log(this.state.mx);
    } else {
      const ioo = this.state.mx.findIndex((object) => {
        return object === e.target.value;
      });
      this.state.mx.splice(ioo, 1);
      // console.log(this.state.mx);
    }
    const t = this.state.mx.toString();
    this.setState({
      [input]: t,
    });
  };
  handleInputProcessor = (input) => (e) => {
    const checked = e.target.checked;
    if (checked) {
      this.state.pro.push(e.target.value);
      // console.log(this.state.pro);
    } else {
      const ioo = this.state.pro.findIndex((object) => {
        return object === e.target.value;
      });
      this.state.pro.splice(ioo, 1);
      // console.log(this.state.pro);
    }
    const t = this.state.pro.toString();
    this.setState({
      [input]: t,
    });
  };
  handleInputGrill = (input) => (e) => {
    const checked = e.target.checked;
    if (checked) {
      this.state.grl.push(e.target.value);
      // console.log(this.state.grl);
    } else {
      const ioo = this.state.grl.findIndex((object) => {
        return object === e.target.value;
      });
      this.state.grl.splice(ioo, 1);
      // console.log(this.state.grl);
    }
    const t = this.state.grl.toString();
    this.setState({
      [input]: t,
    });
  };
  handleInputJuicer = (input) => (e) => {
    const checked = e.target.checked;
    if (checked) {
      this.state.juc.push(e.target.value);
      // console.log(this.state.juc);
    } else {
      const ioo = this.state.juc.findIndex((object) => {
        return object === e.target.value;
      });
      this.state.juc.splice(ioo, 1);
      // console.log(this.state.juc);
    }
    const t = this.state.juc.toString();
    this.setState({
      [input]: t,
    });
  };
  handleCheck = (input) => (e) => {
    this.setState({ [input]: e.target.checked });
    // console.log([input]);
  };
  render() {
    const { step } = this.state;
    const {
      name,
      email,
      phone,
      position,
      country,
      businessName,
      businessPhone,
      type,
      category,
      files,
      fileList,
      notes,
      Instagram,
      Facebook,
      website,
      go,
      goal,
      inter,
      interest,
      ov,
      ovens,
      mx,
      mixers,
      pro,
      processors,
      slicers,
      bladeSize,
      microwave,
      ice,
      grl,
      Grills,
      juc,
      Juicers,
      a,
      b,
      TnC,
    } = this.state;
    const values = {
      name,
      email,
      phone,
      position,
      country,
      businessName,
      businessPhone,
      type,
      category,
      files,
      fileList,
      notes,
      Instagram,
      Facebook,
      website,
      go,
      goal,
      inter,
      interest,
      ov,
      ovens,
      mx,
      mixers,
      pro,
      processors,
      slicers,
      bladeSize,
      microwave,
      ice,
      grl,
      Grills,
      juc,
      Juicers,
      a,
      b,
      TnC,
    };

    switch (step) {
      default:
        return <Page404 />;
      case 1:
        return (
          <Step1
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
          />
        );
      case 2:
        return (
          <Step2
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            values={values}
          />
        );
      case 3:
        return (
          <Step2s1
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            handleFile={this.handleFile}
            handleFl={this.handleFl}
            values={values}
          />
        );
      case 4:
        return (
          <Step2s2
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            values={values}
          />
        );
      case 5:
        return (
          <Step3
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            handleInputGoal={this.handleInputGoal}
            values={values}
          />
        );
      case 6:
        return (
          <Step3s1
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            handleInputIntrest={this.handleInputIntrest}
            values={values}
          />
        );
      case 7:
        return (
          <Step4
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            firstStep={this.firstStep}
            handleChange={this.handleChange}
            handleInputOven={this.handleInputOven}
            handleInputMixer={this.handleInputMixer}
            handleInputProcessor={this.handleInputProcessor}
            handleInputGrill={this.handleInputGrill}
            handleInputJuicer={this.handleInputJuicer}
            values={values}
          />
        );
      case 8:
        return (
          <Summary
            prevStep={this.prevStep}
            firstStep={this.firstStep}
            handleCheck={this.handleCheck}
            values={values}
          />
        );
    }
  }
}

export default GhostForm;
