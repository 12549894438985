import React, { Component, Fragment } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import logo from "../imgs/Group 2.png";
import { Link } from "react-router-dom";
import Img1 from "../imgs/Group 33.png";
import Arrow from "../imgs/Icon awesome-arrow-right.png";
import Arrow1 from "../imgs/Icon awesome-arrow-left.png";
// import { BiCircle } from "react-icons/bi";

import RadioButtonUncheckedRoundedIcon from "@mui/icons-material/RadioButtonUncheckedRounded";
import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel, FormGroup } from "@mui/material";

class Step3 extends Component {
  constructor(props) {
    super(props);
    this.myrefG1 = React.createRef();
    this.myrefG2 = React.createRef();
    this.myrefG3 = React.createRef();
    this.state = {};
  }
  stepValidation = () => {
    const {
      values: { go },
    } = this.props;
    let isValid = true;
    if (go.length === 0) {
      alert("Please input at least 1 goal");
      isValid = false;
    }

    return isValid;
  };
  continue = (e) => {
    e.preventDefault();
    let isValid = this.stepValidation();
    if (isValid) {
      this.props.nextStep();
    }
    // console.log(this.state);
  };
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };
  render() {
    const { handleInputGoal } = this.props;

    return (
      <div>
        {/* Header */}
        <div>
          <nav>
            <div className="sm:flex w-full grid grid-rows-1 grid-cols-6 sm:grid-cols-5 content-center items-center">
              <div className="sm:w-full  sm:px-2 w-50 py-4 pl-16">
                <Link to="/">
                  <img src={logo} alt="Host & Ghost" className="w-20 sm:w-14" />
                </Link>
              </div>
              <div className="sm:hidden"></div>
              <div className="sm:hidden flex items-center content-center">
                <span className="bg-[#FB2306] lg:text-[15px] lg:w-[2.5rem] lg:h-[1.5rem] w-7 h-7 rounded-full grid items-center text-white text-[20px] font-metrischbold">
                  1
                </span>
                <span className="grid w-[124pt] lg:text-[15px] text-[20px] font-metrischbold text-left pl-2">
                  About You
                </span>
              </div>
              <div className="sm:hidden flex items-center content-center w-60">
                <span className="bg-[#FB2306] lg:text-[15px] lg:w-6 lg:h-6 sm:w-5 sm:h-5 w-7 h-7 rounded-full grid items-center text-white sm:text-[14px] text-[20px] font-metrischbold">
                  2
                </span>
                <span className="grid w-[124pt] lg:text-[15px] lg:w-[6rem] sm:w-max sm:text-[14px] text-[20px] font-metrischbold text-left sm:pl-1 pl-2 sm:mr-4">
                  About Your Business
                </span>
              </div>
              <div className=" flex items-center content-center">
                <span className="bg-[#FB2306] lg:text-[15px] sm:text-[14px] lg:w-[2.5rem] lg:h-[1.5rem] w-7 h-7 rounded-full grid items-center text-white text-[20px] font-metrischbold">
                  3
                </span>
                <span className="grid w-[124pt] lg:text-[15px] sm:text-[14px] text-[20px] font-metrischbold text-left pl-2">
                  Your Goals
                </span>
              </div>
              <div className="sm:hidden flex items-center content-center">
                <span className="bg-[#999999] lg:text-[15px] lg:w-[2.5rem] lg:h-[1.5rem] w-7 h-7 rounded-full grid items-center text-white text-[20px] font-metrischbold">
                  4
                </span>
                <span className="grid w-[124pt] lg:text-[15px] text-[#999999] text-[20px] font-metrischbold text-left pl-2">
                  Your Needs
                </span>
              </div>
            </div>
          </nav>
        </div>

        <Fragment>
          <div className="w-full h-full grid">
            <div className="">
              <ProgressBar
                bgColor="#FB2306"
                baseBgColor="#f7f7f7"
                initCompletedOnAnimation={50}
                // animateOnRender={true}
                completed={62.5}
                isLabelVisible={false}
                height="15px"
              />
            </div>
            <div className="grid sm:grid-cols-1 grid-cols-2 h-full">
              <div className="sm:grid hidden sm:mt-4 content-center">
                <img className="px-8" src={Img1} alt=" " />
              </div>
              <div className="grid content-center sm:py-20 py-10 h-[100vh] lg:px-4 sm:h-full sm:pl-4 sm:pr-4 px-16 -mb-20 shadow-[10px_0_10px_-7px_rgba(251,35,6,0.3)]">
                <h2 className="flex justify-start lg:text-[40px] sm:text-[30px] sm:justify-center text-[50px] font-metrischextrabold -mb-4 sm:-mb-1">
                  Your Goals
                </h2>
                <h2 className="flex justify-start sm:text-[20px] text-[25px] sm:justify-center text-[#FB2306] font-metrischbold">
                  What Are Your Goals?
                </h2>
                <h2 className="flex justify-start sm:text-[14px] text-[15px] opacity-50 sm:justify-center font-metrischbold">
                  Provide Your Goals As A Ghost
                </h2>

                <FormGroup className="mr-72 pl-4 lg:mx-0 sm:mx-0 sm:pl-6">
                  <FormControlLabel
                    className="mr-80 mt-4 border-2 border-black rounded-[6px] flex items-center justify-start bg-[#ffffff]"
                    ref={this.myrefG1}
                    control={
                      <Checkbox
                        icon={<RadioButtonUncheckedRoundedIcon />}
                        checkedIcon={<RadioButtonUncheckedRoundedIcon />}
                        sx={{
                          color: "#FB2306",
                          "&.Mui-checked": {
                            color: "#ffffff",
                          },
                        }}
                        value="Testing a new market"
                        onChange={(e) => {
                          handleInputGoal("goal")(e);
                          if (e.target.checked) {
                            this.myrefG1.current.className =
                              " mt-4 mr-3 border-2 -ml-3 border-[#FB2306] rounded-[6px] flex items-center justify-start bg-[#FB2306] text-white";
                          } else {
                            this.myrefG1.current.className =
                              "-ml-3 mr-3 mt-4 border-2 border-black rounded-[6px] flex items-center justify-start bg-[#ffffff]";
                          }
                        }}
                      />
                    }
                    label="Testing a new market"
                  />

                  <FormControlLabel
                    className="mr-80 mt-4 border-2 border-black rounded-[6px] flex items-center justify-start bg-[#ffffff]"
                    ref={this.myrefG2}
                    control={
                      <Checkbox
                        icon={<RadioButtonUncheckedRoundedIcon />}
                        checkedIcon={<RadioButtonUncheckedRoundedIcon />}
                        sx={{
                          color: "#FB2306",
                          "&.Mui-checked": {
                            color: "#ffffff",
                          },
                        }}
                        value="Scaling presence and brand"
                        onChange={(e) => {
                          handleInputGoal("goal")(e);
                          if (e.target.checked) {
                            this.myrefG2.current.className =
                              " mt-4 mr-3 border-2 -ml-3 border-[#FB2306] rounded-[6px] flex items-center justify-start bg-[#FB2306] text-white";
                          } else {
                            this.myrefG2.current.className =
                              "-ml-3 mr-3 mt-4 border-2 border-black rounded-[6px] flex items-center justify-start bg-[#ffffff]";
                          }
                        }}
                      />
                    }
                    label="Scaling presence and brand"
                  />
                  <FormControlLabel
                    className="mr-80 mt-4 border-2 border-black rounded-[6px] flex items-center justify-start bg-[#ffffff]"
                    ref={this.myrefG3}
                    control={
                      <Checkbox
                        icon={<RadioButtonUncheckedRoundedIcon />}
                        checkedIcon={<RadioButtonUncheckedRoundedIcon />}
                        sx={{
                          color: "#FB2306",
                          flex: true,
                          "&.Mui-checked": {
                            color: "#ffffff",
                          },
                        }}
                        value="Home business booster"
                        onChange={(e) => {
                          handleInputGoal("goal")(e);
                          if (e.target.checked) {
                            this.myrefG3.current.className =
                              " mt-4 mr-3 border-2 -ml-3 border-[#FB2306] rounded-[6px] flex items-center justify-start bg-[#FB2306] text-white";
                          } else {
                            this.myrefG3.current.className =
                              "-ml-3 mr-3 mt-4 border-2 border-black rounded-[6px] flex items-center justify-start bg-[#ffffff]";
                          }
                        }}
                      />
                    }
                    label="Home business booster"
                  />
                </FormGroup>
                <br />
                <div className="grid grid-rows-1 grid-cols-2 mt-[23.45rem] lg:mt-8 sm:mt-4 sm:mx-4 xl:mt-0">
                  <div>
                    <button
                      className="flex justify-end content-start text-[#cccccc] font-metrischbold "
                      onClick={this.back}
                    >
                      <img
                        className="mr-2    "
                        src={Arrow1}
                        width="22px"
                        height="22px"
                        alt=" "
                      />
                      Back
                    </button>
                  </div>
                  <div className="flex justify-end lg:px-4">
                    <button
                      className="flex justify-end content-start font-metrischbold "
                      onClick={this.continue}
                    >
                      Continue
                      <img
                        className="ml-2    "
                        src={Arrow}
                        width="22px"
                        height="22px"
                        alt=" "
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div className="grid sm:hidden content-center">
                <img className="px-8" src={Img1} alt=" " />
              </div>
              <br />
            </div>
          </div>
        </Fragment>
      </div>
    );
  }
}

export default Step3;
