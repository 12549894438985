import React, { Component, Fragment } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import logo from "../imgs/Group 2.png";
import { Link } from "react-router-dom";
import Img1 from "../imgs/Group 21.png";
import Arrow from "../imgs/Icon awesome-arrow-right.png";
// import { Modal } from "@mui/material";
// import { Box } from "@mui/system";
// import { TextField } from "@mui/material";
// import Drop from "../imgs/icons/Icon ionic-ios-arrow-down.png";

// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";

class Step1 extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.nameRef = React.createRef();
    this.emailRef = React.createRef();
    this.phoneRef = React.createRef();
    this.positionRef = React.createRef();
  }
  handleCloseF = () => {
    this.setState({
      openF: false,
    });
  };
  stepValidation = () => {
    // const { values } = this.props;
    const {
      values: { name, email, position, phone },
    } = this.props;
    let isValid = true;
    if (name === "") {
      this.nameRef.current.className =
        "p-3 bg-[#FB2306] animate-[bounce_0.7s_ease-in-out_2] lg:mr-[10rem] text-white font-metrisch border-2 border-black hover:border-[#FB2306] mr-60 sm:mx-4 focus:animate-none focus:border-[#FB2306] outline-none rounded-[6px] mt-6";
      isValid = false;
    }
    if (email === "") {
      this.emailRef.current.className =
        "p-3 bg-[#FB2306] animate-[bounce_0.7s_ease-in-out_2] lg:mr-[10rem] text-white font-metrisch border-2 border-black hover:border-[#FB2306] mr-60 sm:mx-4 focus:animate-none focus:border-[#FB2306] outline-none rounded-[6px] mb-1";
      isValid = false;
    }
    if (phone === null) {
      this.phoneRef.current.className =
        "p-3 bg-[#FB2306] animate-[bounce_0.7s_ease-in-out_2] lg:mr-[10rem] text-white font-metrisch border-2 border-black hover:border-[#FB2306] mr-60 sm:mx-4 focus:animate-none focus:border-[#FB2306] outline-none rounded-[6px] mb-1";
      isValid = false;
    }
    if (position === "") {
      this.positionRef.current.className =
        "p-3 bg-[#FB2306] animate-[bounce_0.7s_ease-in-out_2] lg:mr-[10rem] text-white font-metrisch border-2 border-black hover:border-[#FB2306] mr-60 sm:mx-4 focus:animate-none focus:border-[#FB2306] outline-none rounded-[6px] mt-6";
      isValid = false;
    }
    return isValid;
  };
  continue = (e) => {
    e.preventDefault();
    let isValid = this.stepValidation();
    if (isValid) {
      this.props.nextStep();
    }
  };

  handleKeyPress = (e) => {
    var key = e.key;
    var regex = /^[0-9\b]+$/;
    if (regex.test(key) | (key === "Backspace")) {
      // console.log("You pressed a key: " + key);
    } else {
      e.preventDefault();
    }
  };

  render() {
    const { values, handleChange } = this.props;
    return (
      <div className="">
        {/* Header */}
        <div>
          <nav>
            <div className="sm:flex grid grid-rows-1 grid-cols-6 sm:grid-cols-5 content-center items-center w-full">
              <div className="sm:w-full  sm:px-2 w-50 py-4 pl-16">
                <Link to="/">
                  <img src={logo} alt="Host & Ghost" className="w-20 sm:w-14" />
                </Link>
              </div>
              <div className="sm:hidden"></div>
              <div className=" flex items-center content-center">
                <span className="bg-[#FB2306] lg:text-[15px] lg:w-[2.5rem] lg:h-[1.5rem] sm:w-5 sm:h-5 sm:text-[14px] w-7 h-7 rounded-full grid items-center text-white text-[20px] font-metrischbold">
                  1
                </span>
                <span className="grid w-[124pt] sm:text-[14px] lg:text-[15px] text-[20px] font-metrischbold text-left pl-2">
                  About You
                </span>
              </div>
              <div className="sm:hidden flex items-center content-center w-60">
                <span className="bg-[#999999] lg:text-[15px] lg:w-6 lg:h-6 sm:w-5 sm:h-5 w-7 h-7 rounded-full grid items-center text-white sm:text-[14px] text-[20px] font-metrischbold">
                  2
                </span>
                <span className="grid w-[124pt] lg:text-[15px] text-[#999999] lg:w-[6rem] sm:w-max sm:text-[14px] text-[20px] font-metrischbold text-left sm:pl-1 pl-2 sm:mr-4">
                  About Your Business
                </span>
              </div>
              <div className="sm:hidden flex items-center content-center">
                <span className="bg-[#999999] lg:text-[15px]  lg:w-[2.5rem] lg:h-[1.5rem] w-7 h-7 rounded-full grid items-center text-white text-[20px] font-metrischbold">
                  3
                </span>
                <span className="sm:hidden grid w-[124pt] lg:text-[15px] text-[#999999] text-[20px] font-metrischbold text-left pl-2">
                  Your Goals
                </span>
              </div>
              <div className="sm:hidden flex items-center content-center">
                <span className="bg-[#999999] lg:text-[15px] lg:w-[2.5rem] lg:h-[1.5rem] w-7 h-7 rounded-full grid items-center text-white text-[20px] font-metrischbold">
                  4
                </span>
                <span className="grid w-[124pt] lg:text-[15px] text-[#999999] text-[20px] font-metrischbold text-left pl-2">
                  Your Needs
                </span>
              </div>
            </div>
          </nav>
        </div>

        {/* Form */}
        <Fragment>
          <div className="w-full h-full grid">
            <div className="">
              <ProgressBar
                bgColor="#FB2306"
                baseBgColor="#f7f7f7"
                initCompletedOnAnimation={0}
                completed={12.5}
                isLabelVisible={false}
                height="15px"
              />
            </div>
            <div className=" grid sm:grid-cols-1 grid-cols-2 h-full">
              <div className="hidden sm:grid sm:content-center sm:mt-8 ">
                <img className="px-8" src={Img1} alt=" " />
              </div>
              <div className="grid content-center sm:py-20 py-10 h-[100vh] lg:px-4 sm:h-full sm:pl-4 sm:pr-4 px-16 -mb-20 shadow-[10px_0_10px_-7px_rgba(251,35,6,0.3)] xl:mt-0">
                <h2 className="flex justify-start lg:text-[40px] sm:text-[30px] sm:justify-center text-[50px] font-metrischextrabold -mb-4 sm:-mb-1">
                  About You
                </h2>
                <h2 className="flex justify-start lg:text-[20px] sm:text-[20px] text-[25px] sm:justify-center text-[#FB2306] font-metrischbold">
                  Personal Information
                </h2>
                <h2 className="flex justify-start lg:text-[15px] sm:text-[14px] text-[15px] opacity-50 sm:justify-center font-metrischbold">
                  Provide Your Personal Information
                </h2>
                <input
                  ref={this.nameRef}
                  className="p-3 font-metrisch border-2 border-black hover:border-[#FB2306] mr-60 sm:mx-4 lg:mr-[5rem] focus:border-[#FB2306] outline-none rounded-[6px] mt-6"
                  id="name"
                  type="text"
                  placeholder="Your Name"
                  defaultValue={values.name}
                  onChange={handleChange("name")}
                />
                <br />
                <input
                  ref={this.emailRef}
                  className="p-3 font-metrisch border-2 border-black hover:border-[#FB2306] mr-60 lg:mr-[5rem] sm:mx-4 focus:border-[#FB2306] outline-none rounded-[6px]  mb-1"
                  id="email"
                  type="email"
                  placeholder="Your Email"
                  defaultValue={values.email}
                  onChange={handleChange("email")}
                />
                <br />
                <input
                  ref={this.phoneRef}
                  className="p-3 font-metrisch border-2 border-black hover:border-[#FB2306] mr-60 lg:mr-[5rem] sm:mx-4 focus:border-[#FB2306] outline-none rounded-[6px] "
                  id="phone"
                  type="tel"
                  placeholder="Your Mobile Number"
                  onChange={handleChange("phone")}
                  onKeyDown={(e) => {
                    this.handleKeyPress(e);
                  }}
                  defaultValue={values.phone}
                />
                <br />
                <h2 className="flex justify-start text-left lg:text-[40px] sm:text-[30px] sm:justify-center text-[50px] font-metrischextrabold -mb-4 sm:-mb-1 xl:leading-10">
                  About Your Company
                </h2>
                <h2 className="flex justify-start lg:text-[20px] sm:text-[20px] sm:justify-center text-[25px] text-[#FB2306] xl:mt-6 font-metrischbold">
                  Company Position
                </h2>
                <h2 className="flex justify-start lg:text-[15px] sm:text-[14px] sm:justify-center text-[15px] opacity-50 mb-4 sm:mb-0 font-metrischbold">
                  Provide Your Personal Information
                </h2>
                <select
                  ref={this.positionRef}
                  className="p-3 font-metrisch border-2 border-black hover:border-[#FB2306] lg:mr-[5rem] sm:mx-4 mr-60 focus:border-[#FB2306] outline-none rounded-[6px] mt-6 appearance-none"
                  type="text"
                  id="position"
                  defaultValue={values.position}
                  onChange={handleChange("position")}
                >
                  <option value="" disabled>
                    Your Position
                  </option>
                  <option value="Owner">Owner</option>
                  <option value="Head Chef">Head Chef</option>
                  <option value="Operation Manager">Operation Manager</option>
                </select>

                <br />
                <div className="grid grid-rows-1 grid-cols-2 mt-[5.9rem] xl:mt-0">
                  <div></div>
                  <div className="flex justify-end sm:pr-4">
                    <button
                      className="flex content-start font-metrischbold "
                      onClick={this.continue}
                    >
                      Continue
                      <img
                        className="ml-2"
                        src={Arrow}
                        width="22px"
                        height="22px"
                        alt=" "
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div className="grid sm:hidden content-center">
                <img className="px-8" src={Img1} alt=" " />
              </div>
              <br />
            </div>
          </div>
        </Fragment>
      </div>
    );
  }
}

export default Step1;
