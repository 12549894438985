import React, { Component, Fragment } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import logo from "../imgs/Group 2.png";
import { Link } from "react-router-dom";
import Img1 from "../imgs/Group 54.png";
import Arrow from "../imgs/Icon awesome-arrow-right.png";
import Arrow1 from "../imgs/Icon awesome-arrow-left.png";
import OvenO from "../imgs/icons/orange/Oven.png";
import OvenW from "../imgs/icons/white/Oven.png";
import MixerO from "../imgs/icons/orange/Mixer.png";
import MixerW from "../imgs/icons/white/Mixer.png";
import ProcO from "../imgs/icons/orange/Processor.png";
import ProcW from "../imgs/icons/white/Processor.png";
import SliceO from "../imgs/icons/orange/Slicer.png";
import SliceW from "../imgs/icons/white/Slicer.png";
import MicroO from "../imgs/icons/orange/Microwave.png";
import MicroW from "../imgs/icons/white/Microwave.png";
import IceO from "../imgs/icons/orange/Ice Maker.png";
import IceW from "../imgs/icons/white/Ice Maker.png";
import GrillO from "../imgs/icons/orange/Grill.png";
import GrillW from "../imgs/icons/white/Grill.png";
import JuicerO from "../imgs/icons/orange/Juicer.png";
import JuicerW from "../imgs/icons/white/Juicer.png";
import { Modal, Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material";
import Metrisch from "../fonts/Metrisch-Medium-webfont.woff2";

import RadioButtonUncheckedRoundedIcon from "@mui/icons-material/RadioButtonUncheckedRounded";
import RadioButtonCheckedRoundedIcon from "@mui/icons-material/RadioButtonCheckedRounded";
import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel, FormGroup } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const theme = createTheme({
  typography: {
    fontFamily: "Metrisch",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: 'Metrisch';
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src: local('Metrisch'), local('Metrisch'), url(${Metrisch}) format('woff2');
        unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
      }
      `,
    },
  },
  breakpoints: {
    values: {
      xxs: 0, // small phone
      xs: 300, // phone
      sm: 600, // tablets
      lg: 900, // desktop
      xl: 1536, // large screens
    },
  },
});

class Step4 extends Component {
  stepValidation = () => {
    const {
      values: { ov, mx, pro, slicers, bladeSize, microwave, ice, grl, juc },
    } = this.props;
    let isValid = true;
    if (
      ov.length === 0 &&
      mx.length === 0 &&
      pro.length === 0 &&
      slicers === "" &&
      bladeSize === "" &&
      microwave === "" &&
      ice === "" &&
      grl.length === 0 &&
      juc.length === 0
    ) {
      alert("Please input at least 2 Machines");
      isValid = false;
    }

    return isValid;
  };

  first = (e) => {
    e.preventDefault();
    let isValid = this.stepValidation();
    if (isValid) {
      this.props.firstStep();
    }
  };
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };
  continue = (e) => {
    e.preventDefault();
    let isValid = this.stepValidation();
    if (isValid) {
      this.props.nextStep();
    }
  };

  constructor(props) {
    super(props);
    this.Obtn = React.createRef();
    this.Oimg = React.createRef();
    this.Mbtn = React.createRef();
    this.Mimg = React.createRef();
    this.Pbtn = React.createRef();
    this.Pimg = React.createRef();
    this.Sbtn = React.createRef();
    this.Simg = React.createRef();
    this.MWbtn = React.createRef();
    this.MWimg = React.createRef();
    this.Ibtn = React.createRef();
    this.Iimg = React.createRef();
    this.Gbtn = React.createRef();
    this.Gimg = React.createRef();
    this.Jbtn = React.createRef();
    this.Jimg = React.createRef();

    this.o1ref = React.createRef();
    this.o2ref = React.createRef();
    this.o3ref = React.createRef();
    this.o4ref = React.createRef();
    this.o5ref = React.createRef();

    this.state = {
      disabled: false,
      openO: false,
      openM: false,
      openP: false,
      openS: false,
      openMW: false,
      openI: false,
      openG: false,
      openJ: false,
    };
  }

  handleOpenO = () => {
    this.setState({
      openO: true,
    });
  };
  handleOpenM = () => {
    this.setState({
      openM: true,
    });
  };
  handleOpenP = () => {
    this.setState({
      openP: true,
    });
  };
  handleOpenS = () => {
    this.setState({
      openS: true,
    });
  };
  handleOpenMW = () => {
    this.setState({
      openMW: true,
    });
  };
  handleOpenI = () => {
    this.setState({
      openI: true,
    });
  };
  handleOpenG = () => {
    this.setState({
      openG: true,
    });
  };
  handleOpenJ = () => {
    this.setState({
      openJ: true,
    });
  };
  handleCloseO = () => {
    const {
      values: { ovens },
    } = this.props;

    this.setState({
      openO: false,
    });
    if (ovens !== "") {
      this.Obtn.current.className =
        "border-4 bg-[#FB2306] border-[#FB2306] rounded-[6px] mb-2 w-[156px] h-[156px] justify-center grid content-center";
      this.Oimg.current.src = OvenW;
    }
  };
  handleCloseM = () => {
    const {
      values: { mixers },
    } = this.props;
    this.setState({
      openM: false,
    });
    if (mixers !== "") {
      this.Mbtn.current.className =
        "border-4 bg-[#FB2306] border-[#FB2306] rounded-[6px] mb-2 w-[156px] h-[156px] justify-center grid content-center";
      this.Mimg.current.src = MixerW;
    }
  };
  handleCloseP = () => {
    const {
      values: { processors },
    } = this.props;
    this.setState({
      openP: false,
    });
    if (processors !== "") {
      this.Pbtn.current.className =
        "border-4 bg-[#FB2306] border-[#FB2306] rounded-[6px] mb-2 w-[156px] h-[156px] justify-center grid content-center";
      this.Pimg.current.src = ProcW;
    }
  };
  handleCloseS = () => {
    const {
      values: { slicers, bladeSize },
    } = this.props;
    this.setState({
      openS: false,
    });
    if (slicers !== "" && bladeSize !== "") {
      this.Sbtn.current.className =
        "border-4 bg-[#FB2306] border-[#FB2306] rounded-[6px] mb-2 w-[156px] h-[156px] justify-center grid content-center";
      this.Simg.current.src = SliceW;
    }
  };
  handleCloseMW = () => {
    const {
      values: { microwave },
    } = this.props;
    this.setState({
      openMW: false,
    });
    if (microwave !== "") {
      this.MWbtn.current.className =
        "border-4 bg-[#FB2306] border-[#FB2306] rounded-[6px] mb-2 w-[156px] h-[156px] justify-center grid content-center";
      this.MWimg.current.src = MicroW;
    }
  };
  handleCloseI = () => {
    const {
      values: { ice },
    } = this.props;
    this.setState({
      openI: false,
    });
    if (ice !== "") {
      this.Ibtn.current.className =
        "border-4 bg-[#FB2306] border-[#FB2306] rounded-[6px] mb-2 w-[156px] h-[156px] justify-center grid content-center";
      this.Iimg.current.src = IceW;
    }
  };
  handleCloseG = () => {
    const {
      values: { Grills },
    } = this.props;
    this.setState({
      openG: false,
    });
    if (Grills !== "") {
      this.Gbtn.current.className =
        "border-4 bg-[#FB2306] border-[#FB2306] rounded-[6px] mb-2 w-[156px] h-[156px] justify-center grid content-center";
      this.Gimg.current.src = GrillW;
    }
  };
  handleCloseJ = () => {
    const {
      values: { Juicers },
    } = this.props;
    this.setState({
      openJ: false,
    });
    if (Juicers !== "") {
      this.Jbtn.current.className =
        "border-4 bg-[#FB2306] border-[#FB2306] rounded-[6px] mb-2 w-[156px] h-[156px] justify-center grid content-center";
      this.Jimg.current.src = JuicerW;
    }
  };

  render() {
    const {
      values,
      handleChange,
      handleInputOven,
      handleInputMixer,
      handleInputProcessor,
      handleInputGrill,
      handleInputJuicer,
    } = this.props;

    return (
      <>
        {/* Header */}
        <div>
          <nav>
            <div className="sm:flex w-full grid grid-rows-1 grid-cols-6 sm:grid-cols-5 content-center items-center">
              <div className="sm:w-full  sm:px-2 w-50 py-4 pl-16">
                <Link to="/">
                  <img src={logo} alt="Host & Ghost" className="w-20 sm:w-14" />
                </Link>
              </div>
              <div className="sm:hidden"></div>
              <div className="sm:hidden flex items-center content-center">
                <span className="bg-[#FB2306] lg:text-[15px] lg:w-[2.5rem] lg:h-[1.5rem] w-7 h-7 rounded-full grid items-center text-white text-[20px] font-metrischbold">
                  1
                </span>
                <span className="grid w-[124pt] lg:text-[15px] text-[20px] font-metrischbold text-left pl-2">
                  About You
                </span>
              </div>
              <div className="sm:hidden flex items-center content-center w-60">
                <span className="bg-[#FB2306] lg:text-[15px] lg:w-6 lg:h-6 sm:w-5 sm:h-5 w-7 h-7 rounded-full grid items-center text-white sm:text-[14px] text-[20px] font-metrischbold">
                  2
                </span>
                <span className="grid w-[124pt] lg:text-[15px] lg:w-[6rem] sm:w-max sm:text-[14px] text-[20px] font-metrischbold text-left sm:pl-1 pl-2 sm:mr-4">
                  About Your Business
                </span>
              </div>
              <div className=" sm:hidden flex items-center content-center">
                <span className="bg-[#FB2306] lg:text-[15px] sm:text-[14px] lg:w-[2.5rem] lg:h-[1.5rem] w-7 h-7 rounded-full grid items-center text-white text-[20px] font-metrischbold">
                  3
                </span>
                <span className="grid w-[124pt] lg:text-[15px] sm:text-[14px] text-[20px] font-metrischbold text-left pl-2">
                  Your Goals
                </span>
              </div>
              <div className=" flex items-center content-center">
                <span className="bg-[#FB2306] lg:text-[15px] lg:w-[2.5rem] lg:h-[1.5rem] w-7 h-7 rounded-full grid items-center text-white text-[20px] font-metrischbold">
                  4
                </span>
                <span className="grid w-[124pt] lg:text-[15px]  text-[20px] font-metrischbold text-left pl-2">
                  Your Needs
                </span>
              </div>
            </div>
          </nav>
        </div>
        <Fragment>
          <ThemeProvider theme={theme}>
            <div className="w-full h-full grid">
              <div className="">
                <ProgressBar
                  bgColor="#FB2306"
                  baseBgColor="#f7f7f7"
                  initCompletedOnAnimation={75}
                  completed={100}
                  isLabelVisible={false}
                  height="15px"
                />
              </div>
              <div className="grid sm:grid-cols-1 grid-cols-2 h-full">
                <div className="sm:grid hidden sm:mt-4 content-center">
                  <img className="px-8" src={Img1} alt=" " />
                </div>
                <div className="grid content-center sm:py-20 py-10 h-[100vh] sm:h-full lg:h-full sm:pl-4 sm:pr-4 px-16 -mb-20 shadow-[10px_0_10px_-7px_rgba(251,35,6,0.3)]">
                  <h2 className="flex justify-start sm:text-[30px] sm:justify-center text-[50px] font-metrischextrabold -mb-4 sm:-mb-1">
                    Your Needs
                  </h2>
                  <h2 className="flex justify-start sm:text-[20px] text-[25px] sm:justify-center text-[#FB2306] font-metrischbold">
                    Your Kitchen Needs
                  </h2>
                  <h2 className="flex justify-start sm:text-[14px] text-[15px] opacity-50 sm:justify-center font-metrischbold">
                    Provide Us With Your Kitchen Needs
                  </h2>

                  <div className="grid gap-4 lg:gap-x-20 grid-rows-2 grid-cols-4 sm:grid-cols-2 sm:grid-rows-4 lg:grid-cols-2 lg:grid-rows-4 mt-6 xl:gap-x-32">
                    <div className="grid justify-center font-metrischbold">
                      <button
                        ref={this.Obtn}
                        onClick={this.handleOpenO}
                        className="border-4 border-black rounded-[6px] mb-2 w-[156px] h-[156px] justify-center grid content-center"
                      >
                        <img alt=" " src={OvenO} ref={this.Oimg} />
                      </button>
                      <Modal
                        keepMounted
                        open={this.state.openO}
                        onClose={this.handleCloseO}
                      >
                        <Box sx={style}>
                          <div className="grid grid-cols-1 lg:grid-rows-1 justify-center">
                            <div className="flex justify-center content-center">
                              <img src={OvenO} alt=" " />
                            </div>
                            <div className="flex mt-4 justify-center text-[30px] font-metrischbold">
                              Ovens
                            </div>
                            <FormGroup>
                              <div className="grid grid-cols-5 sm:grid-cols-1 gap-0 justify-center mt-4">
                                <div></div>
                                <div>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        ref={this.o1ref}
                                        icon={
                                          <RadioButtonUncheckedRoundedIcon />
                                        }
                                        checkedIcon={
                                          <RadioButtonCheckedRoundedIcon />
                                        }
                                        sx={{
                                          color: "#FB2306",
                                          "&.Mui-checked": {
                                            color: "#FB2306",
                                          },
                                        }}
                                        value="Commercial Oven"
                                        onChange={handleInputOven("ovens")}
                                      />
                                    }
                                    label="Commercial Oven"
                                  />
                                </div>
                                <div>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        ref={this.o2ref}
                                        icon={
                                          <RadioButtonUncheckedRoundedIcon />
                                        }
                                        checkedIcon={
                                          <RadioButtonCheckedRoundedIcon />
                                        }
                                        sx={{
                                          color: "#FB2306",
                                          "&.Mui-checked": {
                                            color: "#FB2306",
                                          },
                                        }}
                                        value="Convection Oven"
                                        onChange={handleInputOven("ovens")}
                                      />
                                    }
                                    label="Convection Oven"
                                  />
                                </div>
                                <div>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        ref={this.o3ref}
                                        icon={
                                          <RadioButtonUncheckedRoundedIcon />
                                        }
                                        checkedIcon={
                                          <RadioButtonCheckedRoundedIcon />
                                        }
                                        sx={{
                                          color: "#FB2306",
                                          flex: true,
                                          "&.Mui-checked": {
                                            color: "#FB2306",
                                          },
                                        }}
                                        value="Combination Oven"
                                        onChange={handleInputOven("ovens")}
                                      />
                                    }
                                    label="Combination Oven"
                                  />
                                </div>
                                <div></div>
                              </div>
                              <div className="grid grid-cols-4 sm:grid-cols-1 lg:mt-0 mt-4">
                                <div></div>
                                <div>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        ref={this.o4ref}
                                        icon={
                                          <RadioButtonUncheckedRoundedIcon />
                                        }
                                        checkedIcon={
                                          <RadioButtonCheckedRoundedIcon />
                                        }
                                        sx={{
                                          color: "#FB2306",
                                          "&.Mui-checked": {
                                            color: "#FB2306",
                                          },
                                        }}
                                        value="Pizza Oven"
                                        onChange={handleInputOven("ovens")}
                                      />
                                    }
                                    label="Pizza Oven"
                                  />
                                </div>
                                <div>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        ref={this.o5ref}
                                        icon={
                                          <RadioButtonUncheckedRoundedIcon />
                                        }
                                        checkedIcon={
                                          <RadioButtonCheckedRoundedIcon />
                                        }
                                        sx={{
                                          color: "#FB2306",
                                          "&.Mui-checked": {
                                            color: "#FB2306",
                                          },
                                        }}
                                        value="Conveyor Oven"
                                        onChange={handleInputOven("ovens")}
                                      />
                                    }
                                    label="Conveyor Oven"
                                  />
                                </div>
                                <div></div>
                              </div>
                              <div className="grid grid-cols-1 grid-rows-1 mt-4 px-[35rem] sm:px-4 lg:px-24  xl:px-[20rem]">
                                <button
                                  onClick={this.handleCloseO}
                                  className=" border-2 border-[#FB2306] rounded-[6px] py-2 text-[#FB2306] hover:text-white hover:bg-[#FB2306] font-metrischbold"
                                >
                                  Save
                                </button>
                              </div>
                            </FormGroup>
                          </div>
                        </Box>
                      </Modal>
                      Oven
                    </div>

                    <div className="grid justify-center font-metrischbold">
                      <button
                        ref={this.Mbtn}
                        onClick={this.handleOpenM}
                        className="border-4 border-black rounded-[6px] mb-2 w-[156px] h-[156px] justify-center grid content-center"
                      >
                        <img alt=" " ref={this.Mimg} src={MixerO} />
                      </button>
                      <Modal
                        keepMounted
                        open={this.state.openM}
                        onClose={this.handleCloseM}
                      >
                        <Box sx={style}>
                          <div className="grid grid-cols-1 justify-center">
                            <div className="flex justify-center content-center">
                              <img src={MixerO} alt=" " />
                            </div>
                            <div className="flex mt-4 justify-center text-[30px] font-metrischbold">
                              Mixers
                            </div>
                            <FormGroup>
                              <div className="grid grid-cols-5 sm:grid-cols-1 ">
                                <div></div>
                                <div className="flex items-center font-metrischbold">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        icon={
                                          <RadioButtonUncheckedRoundedIcon />
                                        }
                                        checkedIcon={
                                          <RadioButtonCheckedRoundedIcon />
                                        }
                                        sx={{
                                          color: "#FB2306",
                                          "&.Mui-checked": {
                                            color: "#FB2306",
                                          },
                                        }}
                                        value="Floor mixers"
                                        onChange={handleInputMixer("mixers")}
                                      />
                                    }
                                    label="Floor mixers"
                                  />
                                </div>
                                <div className="flex items-center font-metrischbold">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        icon={
                                          <RadioButtonUncheckedRoundedIcon />
                                        }
                                        checkedIcon={
                                          <RadioButtonCheckedRoundedIcon />
                                        }
                                        sx={{
                                          color: "#FB2306",
                                          "&.Mui-checked": {
                                            color: "#FB2306",
                                          },
                                        }}
                                        value="Hand mixers"
                                        onChange={handleInputMixer("mixers")}
                                      />
                                    }
                                    label="Hand mixers"
                                  />
                                </div>
                                <div className="flex items-center font-metrischbold">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        icon={
                                          <RadioButtonUncheckedRoundedIcon />
                                        }
                                        checkedIcon={
                                          <RadioButtonCheckedRoundedIcon />
                                        }
                                        sx={{
                                          color: "#FB2306",
                                          "&.Mui-checked": {
                                            color: "#FB2306",
                                          },
                                        }}
                                        value="Countertop mixers"
                                        onChange={handleInputMixer("mixers")}
                                      />
                                    }
                                    label="Countertop mixers"
                                  />
                                </div>
                                <div></div>
                              </div>
                              <div className="grid grid-cols-1 grid-rows-1 mt-4 px-[35rem] sm:px-4 lg:px-24 xl:px-[20rem]">
                                <button
                                  onClick={this.handleCloseM}
                                  className=" border-2 border-[#FB2306] rounded-[6px] py-2 text-[#FB2306] hover:text-white hover:bg-[#FB2306] font-metrischbold"
                                >
                                  Save
                                </button>
                              </div>
                            </FormGroup>
                          </div>
                        </Box>
                      </Modal>
                      Mixer
                    </div>

                    <div className="grid justify-center font-metrischbold">
                      <button
                        ref={this.Pbtn}
                        onClick={this.handleOpenP}
                        className="border-4 border-black rounded-[6px] mb-2 w-[156px] h-[156px] justify-center grid content-center"
                      >
                        <img alt=" " ref={this.Pimg} src={ProcO} />
                      </button>
                      <Modal
                        keepMounted
                        open={this.state.openP}
                        onClose={this.handleCloseP}
                      >
                        <Box sx={style}>
                          <div className="grid grid-cols-1 justify-center">
                            <div className="flex justify-center content-center">
                              <img src={ProcO} alt=" " />
                            </div>
                            <div className="flex mt-4 justify-center text-[30px] font-metrischbold">
                              Processors
                            </div>
                            <FormGroup>
                              <div className="grid grid-cols-5 sm:grid-cols-1 lg:grid-rows-2 ">
                                <div></div>
                                <div className="flex items-center font-metrischbold">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        icon={
                                          <RadioButtonUncheckedRoundedIcon />
                                        }
                                        checkedIcon={
                                          <RadioButtonCheckedRoundedIcon />
                                        }
                                        sx={{
                                          color: "#FB2306",
                                          "&.Mui-checked": {
                                            color: "#FB2306",
                                          },
                                        }}
                                        value="Batch bowl processor"
                                        onChange={handleInputProcessor(
                                          "processors"
                                        )}
                                      />
                                    }
                                    label="Batch bowl processor"
                                  />
                                </div>
                                <div className="flex items-center font-metrischbold">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        icon={
                                          <RadioButtonUncheckedRoundedIcon />
                                        }
                                        checkedIcon={
                                          <RadioButtonCheckedRoundedIcon />
                                        }
                                        sx={{
                                          color: "#FB2306",
                                          "&.Mui-checked": {
                                            color: "#FB2306",
                                          },
                                        }}
                                        value="Continuous feed food processor"
                                        onChange={handleInputProcessor(
                                          "processors"
                                        )}
                                      />
                                    }
                                    label="Continuous feed food processor"
                                  />
                                </div>
                                <div className="flex items-center font-metrischbold">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        icon={
                                          <RadioButtonUncheckedRoundedIcon />
                                        }
                                        checkedIcon={
                                          <RadioButtonCheckedRoundedIcon />
                                        }
                                        sx={{
                                          color: "#FB2306",
                                          "&.Mui-checked": {
                                            color: "#FB2306",
                                          },
                                        }}
                                        value="Buffalo choppers"
                                        onChange={handleInputProcessor(
                                          "processors"
                                        )}
                                      />
                                    }
                                    label="Buffalo choppers"
                                  />
                                </div>
                                <div></div>
                              </div>
                              <div className="flex lg:grid sm:grid sm:grid-cols-1 items-center font-metrischbold justify-center lg:mt-0 sm:mt-0 mt-4">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      icon={<RadioButtonUncheckedRoundedIcon />}
                                      checkedIcon={
                                        <RadioButtonCheckedRoundedIcon />
                                      }
                                      sx={{
                                        color: "#FB2306",
                                        "&.Mui-checked": {
                                          color: "#FB2306",
                                        },
                                      }}
                                      value="Combination processors"
                                      onChange={handleInputProcessor(
                                        "processors"
                                      )}
                                    />
                                  }
                                  label="Combination processors"
                                />
                              </div>
                              <div className="grid grid-cols-1 grid-rows-1 mt-4 px-[35rem] sm:px-4 lg:px-24 xl:px-[20rem]">
                                <button
                                  onClick={this.handleCloseP}
                                  className=" border-2 border-[#FB2306] rounded-[6px] py-2 text-[#FB2306] hover:text-white hover:bg-[#FB2306] font-metrischbold"
                                >
                                  Save
                                </button>
                              </div>
                            </FormGroup>
                          </div>
                        </Box>
                      </Modal>
                      Processors
                    </div>

                    <div className="grid justify-center font-metrischbold">
                      <button
                        ref={this.Sbtn}
                        onClick={this.handleOpenS}
                        className="border-4 border-black rounded-[6px] mb-2 w-[156px] h-[156px] justify-center grid content-center"
                      >
                        <img alt=" " ref={this.Simg} src={SliceO} />
                      </button>
                      <Modal
                        keepMounted
                        open={this.state.openS}
                        onClose={this.handleCloseS}
                      >
                        <Box sx={style}>
                          <div className="grid grid-cols-1 justify-center">
                            <div className="flex justify-center content-center">
                              <img src={SliceO} alt=" " />
                            </div>
                            <div className="flex mt-4 justify-center text-[30px] font-metrischbold">
                              Slicers
                            </div>
                            <div className="grid grid-cols-2 gap-4 grid-rows-1">
                              <div>
                                <select
                                  className="p-3 font-metrisch border-2 border-black hover:border-[#FB2306] w-full focus:border-[#FB2306] outline-none rounded-[6px] "
                                  defaultValue={values.slicers}
                                  onChange={handleChange("slicers")}
                                >
                                  <option value="" disabled>
                                    --Criteria--
                                  </option>
                                  <option value="Automatic">Automatic</option>
                                  <option value="Manual">Manual</option>
                                </select>
                              </div>
                              <div>
                                <select
                                  className="p-3 font-metrisch border-2 border-black hover:border-[#FB2306] w-full focus:border-[#FB2306] outline-none rounded-[6px] "
                                  defaultValue={values.bladeSize}
                                  onChange={handleChange("bladeSize")}
                                >
                                  <option value="" disabled>
                                    --Blade Size--
                                  </option>
                                  <option value="Light">Light</option>
                                  <option value="Medium">Medium</option>
                                  <option value="Heavy-duty">Heavy-duty</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="grid grid-cols-1 grid-rows-1 mt-4 px-[35rem] sm:px-4 lg:px-24 xl:px-[20rem]">
                            <button
                              onClick={this.handleCloseS}
                              className=" border-2 border-[#FB2306] rounded-[6px] py-2 text-[#FB2306] hover:text-white hover:bg-[#FB2306] font-metrischbold"
                            >
                              Save
                            </button>
                          </div>
                        </Box>
                      </Modal>
                      Slicers
                    </div>

                    <div className="grid justify-center font-metrischbold">
                      <button
                        ref={this.MWbtn}
                        onClick={this.handleOpenMW}
                        className="border-4 border-black rounded-[6px] mb-2 w-[156px] h-[156px] justify-center grid content-center"
                      >
                        <img alt=" " ref={this.MWimg} src={MicroO} />
                      </button>
                      <Modal
                        keepMounted
                        open={this.state.openMW}
                        onClose={this.handleCloseMW}
                      >
                        <Box sx={style}>
                          <div className="grid grid-cols-1 justify-center">
                            <div className="flex justify-center content-center">
                              <img src={MicroO} alt=" " />
                            </div>
                            <div className="flex mt-4 justify-center text-[30px] font-metrischbold">
                              Microwave
                            </div>
                            <div className="grid grid-cols-1 gap-4 grid-rows-1">
                              <div>
                                <select
                                  className="p-3 font-metrisch border-2 border-black hover:border-[#FB2306] w-full focus:border-[#FB2306] outline-none rounded-[6px] "
                                  defaultValue={values.microwave}
                                  onChange={handleChange("microwave")}
                                >
                                  <option value="" disabled>
                                    --Criteria--
                                  </option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="grid grid-cols-1 grid-rows-1 mt-4 px-[35rem] sm:px-4 lg:px-24 xl:px-[20rem]">
                            <button
                              onClick={this.handleCloseMW}
                              className=" border-2 border-[#FB2306] rounded-[6px] py-2 text-[#FB2306] hover:text-white hover:bg-[#FB2306] font-metrischbold"
                            >
                              Save
                            </button>
                          </div>
                        </Box>
                      </Modal>
                      Microwave
                    </div>

                    <div className="grid justify-center font-metrischbold">
                      <button
                        ref={this.Ibtn}
                        onClick={this.handleOpenI}
                        className="border-4 border-black rounded-[6px] mb-2 w-[156px] h-[156px] justify-center grid content-center"
                      >
                        <img alt=" " ref={this.Iimg} src={IceO} />
                      </button>
                      <Modal
                        keepMounted
                        open={this.state.openI}
                        onClose={this.handleCloseI}
                      >
                        <Box sx={style}>
                          <div className="grid grid-cols-1 justify-center">
                            <div className="flex justify-center content-center">
                              <img src={IceO} alt=" " />
                            </div>
                            <div className="flex mt-4 justify-center text-[30px] font-metrischbold">
                              Ice Maker
                            </div>
                            <div className="grid grid-cols-1 gap-4 grid-rows-1">
                              <div>
                                <select
                                  className="p-3 font-metrisch border-2 border-black hover:border-[#FB2306] w-full focus:border-[#FB2306] outline-none rounded-[6px] "
                                  defaultValue={values.ice}
                                  onChange={handleChange("ice")}
                                >
                                  <option value="" disabled>
                                    --Criteria--
                                  </option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="grid grid-cols-1 grid-rows-1 mt-4 px-[35rem] sm:px-4 lg:px-24 xl:px-[20rem]">
                            <button
                              onClick={this.handleCloseI}
                              className=" border-2 border-[#FB2306] rounded-[6px] py-2 text-[#FB2306] hover:text-white hover:bg-[#FB2306] font-metrischbold"
                            >
                              Save
                            </button>
                          </div>
                        </Box>
                      </Modal>
                      Ice Maker
                    </div>

                    <div className="grid justify-center font-metrischbold">
                      <button
                        ref={this.Gbtn}
                        onClick={this.handleOpenG}
                        className="border-4 border-black rounded-[6px] mb-2 w-[156px] h-[156px] justify-center grid content-center"
                      >
                        <img alt=" " ref={this.Gimg} src={GrillO} />
                      </button>
                      <Modal
                        keepMounted
                        open={this.state.openG}
                        onClose={this.handleCloseG}
                      >
                        <Box sx={style}>
                          <div className="grid grid-cols-1 justify-center">
                            <div className="flex justify-center content-center">
                              <img src={GrillO} alt=" " />
                            </div>
                            <div className="flex mt-4 justify-center text-[30px] font-metrischbold">
                              Grill
                            </div>
                            <FormGroup>
                              <div className="grid grid-cols-2 sm:grid-cols-1 lg:grid-rows-1 ">
                                <div className="flex items-center justify-center ">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        icon={
                                          <RadioButtonUncheckedRoundedIcon />
                                        }
                                        checkedIcon={
                                          <RadioButtonCheckedRoundedIcon />
                                        }
                                        sx={{
                                          color: "#FB2306",
                                          "&.Mui-checked": {
                                            color: "#FB2306",
                                          },
                                        }}
                                        value="Gas Grill"
                                        onChange={handleInputGrill("Grills")}
                                      />
                                    }
                                    label="Gas Grill"
                                  />
                                </div>
                                <div className="flex items-center justify-center">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        icon={
                                          <RadioButtonUncheckedRoundedIcon />
                                        }
                                        checkedIcon={
                                          <RadioButtonCheckedRoundedIcon />
                                        }
                                        sx={{
                                          color: "#FB2306",
                                          "&.Mui-checked": {
                                            color: "#FB2306",
                                          },
                                        }}
                                        value="Electric Grill"
                                        onChange={handleInputGrill("Grills")}
                                      />
                                    }
                                    label="Electric Grill"
                                  />
                                </div>
                              </div>
                              <div className="grid grid-cols-1 grid-rows-1 mt-4 px-[35rem] sm:px-4 lg:px-24 xl:px-[20rem]">
                                <button
                                  onClick={this.handleCloseG}
                                  className=" border-2 border-[#FB2306] rounded-[6px] py-2 text-[#FB2306] hover:text-white hover:bg-[#FB2306] font-metrischbold"
                                >
                                  Save
                                </button>
                              </div>
                            </FormGroup>
                          </div>
                        </Box>
                      </Modal>
                      Grill
                    </div>

                    <div className="grid justify-center font-metrischbold">
                      <button
                        ref={this.Jbtn}
                        onClick={this.handleOpenJ}
                        className="border-4 border-black rounded-[6px] mb-2 w-[156px] h-[156px] justify-center grid content-center"
                      >
                        <img alt=" " ref={this.Jimg} src={JuicerO} />
                      </button>
                      <Modal
                        keepMounted
                        open={this.state.openJ}
                        onClose={this.handleCloseJ}
                      >
                        <Box sx={style}>
                          <div className="grid grid-cols-1 justify-center">
                            <div className="flex justify-center content-center">
                              <img src={JuicerO} alt=" " />
                            </div>
                            <div className="flex mt-4 justify-center text-[30px] font-metrischbold">
                              Juicers
                            </div>
                            <FormGroup>
                              <div className="grid grid-cols-4 grid-rows-2 sm:grid-cols-1 ">
                                <div className="flex items-center justify-center ">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        icon={
                                          <RadioButtonUncheckedRoundedIcon />
                                        }
                                        checkedIcon={
                                          <RadioButtonCheckedRoundedIcon />
                                        }
                                        sx={{
                                          color: "#FB2306",
                                          "&.Mui-checked": {
                                            color: "#FB2306",
                                          },
                                        }}
                                        value="Citrus juicer"
                                        onChange={handleInputJuicer("Juicers")}
                                      />
                                    }
                                    label="Citrus juicer"
                                  />
                                </div>
                                <div className="flex items-center justify-center ">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        icon={
                                          <RadioButtonUncheckedRoundedIcon />
                                        }
                                        checkedIcon={
                                          <RadioButtonCheckedRoundedIcon />
                                        }
                                        sx={{
                                          color: "#FB2306",
                                          "&.Mui-checked": {
                                            color: "#FB2306",
                                          },
                                        }}
                                        value="Blender juicer"
                                        onChange={handleInputJuicer("Juicers")}
                                      />
                                    }
                                    label="Blender juicer"
                                  />
                                </div>
                                <div className="flex items-center justify-center ">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        icon={
                                          <RadioButtonUncheckedRoundedIcon />
                                        }
                                        checkedIcon={
                                          <RadioButtonCheckedRoundedIcon />
                                        }
                                        sx={{
                                          color: "#FB2306",
                                          "&.Mui-checked": {
                                            color: "#FB2306",
                                          },
                                        }}
                                        value="Commercial juicer"
                                        onChange={handleInputJuicer("Juicers")}
                                      />
                                    }
                                    label="Commercial juicer"
                                  />
                                </div>
                                <div className="flex items-center justify-center ">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        icon={
                                          <RadioButtonUncheckedRoundedIcon />
                                        }
                                        checkedIcon={
                                          <RadioButtonCheckedRoundedIcon />
                                        }
                                        sx={{
                                          color: "#FB2306",
                                          "&.Mui-checked": {
                                            color: "#FB2306",
                                          },
                                        }}
                                        value="Electric juicer"
                                        onChange={handleInputJuicer("Juicers")}
                                      />
                                    }
                                    label="Electric juicer"
                                  />
                                </div>
                                <div className="flex items-center justify-center ">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        icon={
                                          <RadioButtonUncheckedRoundedIcon />
                                        }
                                        checkedIcon={
                                          <RadioButtonCheckedRoundedIcon />
                                        }
                                        sx={{
                                          color: "#FB2306",
                                          "&.Mui-checked": {
                                            color: "#FB2306",
                                          },
                                        }}
                                        value="Centrifugal juicer"
                                        onChange={handleInputJuicer("Juicers")}
                                      />
                                    }
                                    label="Centrifugal juicer"
                                  />
                                </div>
                                <div className="flex items-center justify-center ">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        icon={
                                          <RadioButtonUncheckedRoundedIcon />
                                        }
                                        checkedIcon={
                                          <RadioButtonCheckedRoundedIcon />
                                        }
                                        sx={{
                                          color: "#FB2306",
                                          "&.Mui-checked": {
                                            color: "#FB2306",
                                          },
                                        }}
                                        value="Masticating juicer"
                                        onChange={handleInputJuicer("Juicers")}
                                      />
                                    }
                                    label="Masticating juicer"
                                  />
                                </div>
                                <div className="flex items-center justify-center ">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        icon={
                                          <RadioButtonUncheckedRoundedIcon />
                                        }
                                        checkedIcon={
                                          <RadioButtonCheckedRoundedIcon />
                                        }
                                        sx={{
                                          color: "#FB2306",
                                          "&.Mui-checked": {
                                            color: "#FB2306",
                                          },
                                        }}
                                        value="Twin gear juicer"
                                        onChange={handleInputJuicer("Juicers")}
                                      />
                                    }
                                    label="Twin gear juicer"
                                  />
                                </div>
                                <div className="flex items-center justify-center ">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        icon={
                                          <RadioButtonUncheckedRoundedIcon />
                                        }
                                        checkedIcon={
                                          <RadioButtonCheckedRoundedIcon />
                                        }
                                        sx={{
                                          color: "#FB2306",
                                          "&.Mui-checked": {
                                            color: "#FB2306",
                                          },
                                        }}
                                        value="Juice press"
                                        onChange={handleInputJuicer("Juicers")}
                                      />
                                    }
                                    label="Juice press"
                                  />
                                </div>
                              </div>
                              <div className="grid grid-cols-1 grid-rows-1 mt-4 px-[35rem] sm:px-4 lg:px-24 xl:px-[20rem]">
                                <button
                                  onClick={this.handleCloseJ}
                                  className=" border-2 border-[#FB2306] rounded-[6px] py-2 text-[#FB2306] hover:text-white hover:bg-[#FB2306] font-metrischbold"
                                >
                                  Save
                                </button>
                              </div>
                            </FormGroup>
                          </div>
                        </Box>
                      </Modal>
                      Juicer
                    </div>
                  </div>

                  <br />
                  <div className="grid grid-rows-1 grid-cols-2 mt-[9.1rem] sm:mt-4 xl:mt-0">
                    <div>
                      <button
                        className="flex justify-end content-start text-[#cccccc] font-metrischbold "
                        onClick={this.back}
                      >
                        <img
                          className="mr-2    "
                          src={Arrow1}
                          width="22px"
                          height="22px"
                          alt=" "
                        />
                        Back
                      </button>
                    </div>
                    <div className="flex justify-end">
                      <button
                        className="flex justify-end content-start font-metrischbold "
                        onClick={this.continue}
                      >
                        Continue
                        <img
                          className="ml-2    "
                          src={Arrow}
                          width="22px"
                          height="22px"
                          alt=" "
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="sm:hidden grid content-center">
                  <img className="px-8" src={Img1} alt=" " />
                </div>
                <br />
              </div>
            </div>
          </ThemeProvider>
        </Fragment>
      </>
    );
  }
}

export default Step4;
